import ProfileEditForm from "./ProfileEditForm";
import { supabase } from "../api/supabase";
import useUser from "../hook/useUser";
import { useQuery } from "@tanstack/react-query";
import LoaderSpinner from "./Loader";

export async function getProfile(userId) {
  let { data: profile, error } = await supabase
    .from("profiles")
    .select(
      "email, firstname, lastname, phonenumber, referralcode, profileimage"
    )
    .eq("id", userId);

  if (error) {
    throw new Error("Not able to acess the data");
  }
  return profile[0];
}

//get user data and store in react query cache in session exists in local storage
export function useGetProfile(id) {
  const { data, isLoading } = useQuery({
    queryKey: ["profile"],
    queryFn: () => getProfile(id),
  });

  return {
    data,
    isLoading,
  };
}

export default function ProfileImage({style}) {
  const {
    data: userdata,
    isLoading: userLoading,
    isAuthenticated: auth,
  } = useUser();
  const userId = userdata?.id;

  const { data, isLoading } = useGetProfile(userId);

  return (
    <div>
      {data ? (
        <Image style={style} preloadedValues={data} userId={userId} />
      ) : (
        <LoaderSpinner />
      )}
    </div>
  );
}

export function Image({ preloadedValues, style}) {
  const { profileimage } = preloadedValues;
  console.log("dashboard", profileimage);
  console.log("dashboard", preloadedValues);
  return (
      <img
       className={style}
        src={
          profileimage ||
          "https://stomcnibgnqyffgvnadu.supabase.co/storage/v1/object/public/profileimages/avatar.jpg"
        }
        alt={preloadedValues.firstname}
      />
  );
}
