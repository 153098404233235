import React, { useEffect } from "react";
// import Img from "../assets/accountCreate/view-3d-man-using-laptop (2).png";
// import MobileImg from "../assets/accountCreate/login.png";
//new add
import { useState } from "react";
import useLogin from "../hook/useLogin";
import useUser from "../hook/useUser";
import { useNavigate } from "react-router-dom";
import LoaderSpinner from "../components_/Loader";
import { NavLink } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import getHome from "../api/getHomepage";
import Image from "../utilities/Image";

const Login = () => {
  //new add

  const {
    data: image,
    isLoading: load,
    error: imageerror,
  } = useQuery({
    queryKey: ["home"],
    queryFn: getHome,
  });

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //calling custom login hook
  const { data, login, isLoading, error } = useLogin();

  const { isLoading: loading, isAuthenticated } = useUser();
  const navigate = useNavigate();

  // if user is authenticated, redirect page to dashboard
  useEffect(
    function () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });

      if (isAuthenticated && !loading) {
        navigate("/dashboard/main");
      }
    },
    [loading, isAuthenticated, navigate]
  );

  function handleSubmit(e) {
    e.preventDefault();
    if (!email || !password) return;
    login(
      { email, password },
      {
        onSettled: () => {
          setEmail("");
          setPassword("");
        },
      }
    );
  }

  //old
  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, []);
  if (!isAuthenticated)
    return (
      <LoaderSpinner loading={isLoading} >
      <div className="py-6">
        <div className="">
          <div className="flex flex-col lg:flex-row">
            <h1 className="lg:text-3xl block text-2xl lg:hidden font-bold mb-2 text-center">
              Sign in to InterestSave
            </h1>

            <div className="mx-auto">
              <div className="mx-auto w-full md:w-[40rem]">
                <Image
                  className="lg:block hidden"
                  src={
                    image?.filter((i) => i.imagename == "login")[0]?.imageurl
                  }
                  alt="login"
                />
                <Image
                  className="lg:hidden block sm:w-96 w-72 mx-auto "
                  src={
                    image?.filter((i) => i.imagename == "login")[0]?.imageurl
                  }
                  alt="login"
                />
              </div>
            </div>

            {/* Right Side (Form) */}
            <div className="w-full lg:w-1/2 flex justify-center mt-6 lg:py-16">
              <div className="w-3/4 mx-auto">
                <h1 className="text-3xl hidden lg:block font-bold mb-2 text-center">
                  Sign in to InterestSave
                </h1>
                <div className="lg:mt-14">
                  {/* new add */}
                  <form onSubmit={(e) => handleSubmit(e)}>
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-600 mb-1"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        autoComplete="email"
                        value={email}
                        onChange={(e) => setEmail((prev) => e.target.value)}
                        disabled={isLoading}
                        className="border-b lg:border lg:rounded-lg lg:mt-2 border-gray-300 outline-none w-full py-2 lg:p-2"
                      />
                    </div>

                    {/* Password */}
                    <div className="mt-4">
                      <div className="flex justify-between">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-600 mb-1"
                        >
                          Password
                        </label>
                        <p>
                          <a className="underline cursor-pointer">
                            <NavLink to="/forgotpassword">Forgot Password?</NavLink>
                          </a>
                        </p>
                      </div>
                      <input
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword((prev) => e.target.value)}
                        disabled={isLoading}
                        className="border-b lg:border lg:rounded-lg lg:mt-2 border-gray-300 outline-none w-full py-2 lg:p-2"
                      />
                    </div>

                    {/* Sign In Button */}
                    <button
                      disabled={isLoading}
                      className="bg-[#50A499] text-white p-2 rounded-full w-full mt-8"
                    >
                      {!isLoading ? "Sign In" : "signing In.."}
                    </button>
                  </form>

                  {/* Don't have an account? Sign up */}
                  <p className="mt-8 text-center">
                    Don't have an account?{" "}
                    <NavLink to="/signup">
                      {" "}
                      <div href="/signup" className="text-[#50A499]">
                        Sign up
                      </div>
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </LoaderSpinner>
    );
};

export default Login;
