import React from 'react'

const BlobStatic = ({size}) => {
  return (
    <div>
      <div className={`absolute w-[${size}px] h-[${size}px] top-10 left-40 filter blur-xl opacity-30 animate-blob bg-teal-400 mix-blend-multiply rounded-full`}></div>
      <div className={`absolute w-[${size}px] h-[${size}px] filter blur-xl opacity-30 animate-blob animation-delay-4000 bg-blue-400 mix-blend-multiply rounded-full`}>
    </div>
    </div>
  )
}

export default BlobStatic
