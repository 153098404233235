import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { updateProfileData } from "../api/CreateProfile";

export default function useProfileEdit() {
  //navigate to different page on logout
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    mutate: profileEdit,
    isLoading,
    error,
  } = useMutation({
    mutationFn: updateProfileData,
    onSuccess: (data) => {
      toast.success("updated successfully");
      queryClient.setQueryData(["profile"], data[0]);
    },
    onError: (err) => {
      toast.error("Not able to update");
    },
  });

  return { profileEdit, isLoading, error };
}
