import ProfileEditForm from "../components_/ProfileEditForm";
import { supabase } from "../api/supabase";
import useUser from "../hook/useUser";
import { useQuery } from "@tanstack/react-query";
import LoaderSpinner from "../components_/Loader";

export async function getProfile(userId) {
  let { data: profile, error } = await supabase
    .from("profiles")
    .select(
      "email, firstname, lastname, phonenumber, referralcode, profileimage"
    )
    .eq("id", userId);

  if (error) {
    throw new Error("Not able to acess the data");
  }
  return profile[0];
}

//get user data and store in react query cache in session exists in local storage
export function useGetProfile(id) {
  const { data, isLoading } = useQuery({
    queryKey: ["profile"],
    queryFn: () => getProfile(id),
  });

  return {
    data,
    isLoading,
  };
}

export default function Profile() {
  console.log("This is profile page");
  const {
    data: userdata,
    isLoading: userLoading,
    isAuthenticated: auth,
  } = useUser();
  const userId = userdata?.id;

  const { data, isLoading } = useGetProfile(userId);

  return (
    <div>
      {data ? (
        <div className="">
          <h1 className="text-3xl font-semibold ">Update Your Profile</h1>
          <ProfileEditForm preloadedValues={data} userId={userId} />
        </div>
      ) : (
        <LoaderSpinner />
      )}
    </div>
  );
}
