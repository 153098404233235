import { supabase } from "./supabase";
export async function ForgotPasswordReset(email) {
  console.log("api email", email);
  let { data, error } = await supabase.auth.resetPasswordForEmail(email);

  if (error) throw new Error(error.message);

  console.log(data, error);

  return data;
}
