import { createContext, useRef, useState } from "react";

export const refContext = createContext({
    benefitRef: null,
    aboutusRef: null,
    faqsRef:null,
    podcastRef: null,
    saveMoney:null,
    scrollToView: ()=>{},
})

const ContextProvider = ({children}) =>{
    const benefitRef = useRef(null);
    const aboutusRef = useRef(null);
    const faqsRef = useRef(null);
    const podcastRef = useRef(null);
    const saveMoney = useRef(null);

    const scrollToView = (ref, navigate) => {
        if(ref.current===null){
            navigate('/')
            
        }
        else ref.current.scrollIntoView({block:'start', scroll:'smooth'})
        
        
    }

    const newRefObject = {
        benefitRef, aboutusRef, faqsRef, podcastRef, saveMoney, scrollToView
    }
    return(
        <refContext.Provider value={newRefObject} >
            {children}
        </refContext.Provider>
    )
}

export default ContextProvider;