import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { updatePassword } from "../api/UpdatePassword";

export function useUpdatePassword() {
  const queryClient = useQueryClient();

  const { mutate: updatepassword, isLoading: isUpdating } = useMutation({
    mutationFn: updatePassword,
    onSuccess: (user) => {
      toast.success("password successfully updated");
      // console.log("password updated", user.user);
      queryClient.setQueryData(["userdata"], user.user);
    },
    onError: (err) => toast.error(err.message),
  });

  return { updatepassword, isUpdating };
}
