import { supabase, supabaseUrl } from "./supabase";
export async function getProfileData(id) {
  // console.log("received id", id);
  let { data: profile, error } = await supabase
    .from("profiles")
    .select("email, firstname, lastname, phonenumber, referralcode")
    .eq("id", id);

  if (error) {
    throw new Error("Not able to acess the data");
  }

  // console.log("get profile data", profile[0]);
  return profile;
}

export async function deleteProfileData(id) {
  const { data, error } = await supabase.from("profiles").delete().eq("id", id);

  // console.log(data);
}

export async function updateProfileData(profiledata) {
  // console.log("update profile data", profiledata);
  const { id, image, ...userdata } = profiledata;
  // console.log("update profile data", id, userdata);
  // console.log("update profile data", image, image[0]?.name);
  // console.log(Boolean(image[0]));

  //update profile data without no image
  if (!Boolean(image[0])) {
    const { data, error } = await supabase
      .from("profiles")
      .update(userdata)
      .eq("id", id)
      .select();

    return data;
  }
  if (Boolean(image[0])) {
    const imagename = `${Math.random()}-${image[0]?.name}`.replaceAll("/", "");
    const imagepath = `${supabaseUrl}/storage/v1/object/public/profileimages/${imagename}`;
    const { data, error } = await supabase
      .from("profiles")
      .update({ ...userdata, profileimage: imagepath })
      .eq("id", id)
      .select();

    const { data: imagedata, error: storageerror } = await supabase.storage
      .from("profileimages")
      .upload(imagename, image[0]);

    if (storageerror) {
      const { data, error } = await supabase
        .from("profiles")
        .update({ ...userdata, profileimage: "" })
        .eq("id", id)
        .select();
      throw new Error("couldnot upload image");
    }
    return data;
  }
}
