import React from 'react'

const Overlay = ({open, onClickFunc, style}) => {
  return (
    <div onClick={onClickFunc} className={`${style} ${open?'opacity-70 w-full' :'opacity-0'}`}>
      
    </div>
  )
}

export default Overlay
