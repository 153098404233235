import GetCurrentUser from "../api/getCurrentUser";

const PaymentHandler = async (e, amount, user, setLoading) => {
  console.log(e.currentTarget.name, amount, user);
  console.time("payment");
  const description = e.currentTarget.name;
  const data = await fetch(`${process.env.REACT_APP_PAYMENT_API}/api/getkey`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const { key } = await data.json();
  const response = await fetch(
    `${process.env.REACT_APP_PAYMENT_API}/api/checkout`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount,
      }),
    }
  );

  const { success, order } = await response.json();
  let options;
  if (user) {
    // const {id} = await GetCurrentUser();
    console.log(user);
    options = {
      key,
      amount: order.amount,
      currency: "INR",
      name: "Interest Save",
      description,
      image: "https://avatars.githubusercontent.com/u/151510697?v=4",
      order_id: order.id,
      callback_url: `${process.env.REACT_APP_PAYMENT_API}/api/paymentVerify/?user_id=${user.id}&first_name=${user.user_metadata.firstname}&last_name=${user.user_metadata.lastname}`,
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#22A699",
      },
    };
  } else {
    options = {
      key,
      amount: order.amount,
      currency: "INR",
      name: "Interest Save",
      description,
      image: "https://avatars.githubusercontent.com/u/151510697?v=4",
      order_id: order.id,
      callback_url: `${process.env.REACT_APP_PAYMENT_API}/api/paymentVerify`,
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#22A699",
      },
    };
  }
  setLoading(false);
  const razor = new window.Razorpay(options);
  razor.open();
};

export default PaymentHandler;
