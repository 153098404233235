import { supabase } from "./supabase";

//load authenticated user
export default async function GetCurrentUser() {
  //first we check if there is an active session by getting data from local storage
  const { data } = await supabase.auth.getSession();
  // console.log("data from local", data);
  if (!data.session) return null;

  //get user data from server again if session exists/logged in user
  const {
    data: { user },
  } = await supabase.auth.getUser();

  // console.log("get user data", user);

  return user;
}
