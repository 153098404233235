import React from "react";
import Tilt from "react-parallax-tilt";
import Image from "../utilities/Image";

const Stats = ({ loan, isLoading }) => {
  return (
    <div>
      <div className="mx-auto flex flex-wrap mt-4 md:mt-10">
        <div className="flex flex-wrap flex-col md:flex-row justify-around py-6 gap-6 items-center mx-auto">
          {/* <Blob /> */}
          <Tilt
            className=""
            tiltMaxAngleX={15}
            tiltMaxAngleY={13}
            perspective={700}
          >
            <div className="py-4 px-6 rounded-lg duration-200 hover:shadow-lg shadow-neutral-400/30 backdrop-filter backdrop-blur-md bg-opacity-10">
              <div className="w-[13rem]">
                <Image
                  className="w-full"
                  src="https://stomcnibgnqyffgvnadu.supabase.co/storage/v1/object/public/homepage/loan/stats1_optimised.png"
                  alt="stats"
                />
              </div>
              <div className="text-center w-44 mx-auto">
                <h1 className="text-2xl font-bold text-[#22A699]">740+</h1>
                <p className="font-semibold">Students signed up so far</p>
              </div>
            </div>
          </Tilt>
          <Tilt
            className=""
            tiltMaxAngleX={15}
            tiltMaxAngleY={13}
            perspective={700}
          >
            <div className="py-4 px-6 rounded-lg duration-200 hover:shadow-lg shadow-neutral-400/30 backdrop-filter backdrop-blur-md bg-opacity-10">
              <div className="w-[13rem]">
                <Image
                  className="w-full"
                  src="https://stomcnibgnqyffgvnadu.supabase.co/storage/v1/object/public/homepage/loan/stats2_optimised.png"
                  alt="stats"
                />
              </div>
              <div className="text-center w-44 mx-auto">
                <h1 className="text-2xl font-bold text-[#22A699]">Upto 4%</h1>
                <p className="font-semibold">For InterestSave Members</p>
              </div>
            </div>
          </Tilt>
          <Tilt
            className=""
            tiltMaxAngleX={15}
            tiltMaxAngleY={13}
            perspective={700}
          >
            <div className="py-4 px-6 rounded-lg duration-200 hover:shadow-lg shadow-neutral-400/30 backdrop-filter backdrop-blur-md bg-opacity-10">
              <div className="w-[13rem]">
                <Image
                  className="w-full"
                  src="https://stomcnibgnqyffgvnadu.supabase.co/storage/v1/object/public/homepage/loan/stats3_optimised.png"
                  alt="stats"
                />
              </div>
              <div className="text-center w-44 mx-auto">
                <h1 className="text-2xl font-bold text-[#22A699]">695+</h1>
                <p className="font-semibold">
                  Students booked for mock interview
                </p>
              </div>
            </div>
          </Tilt>
        </div>
      </div>
    </div>
  );
};

export default Stats;
