import React from "react";
import Talk from "../assets/BuyIllustrations/talk.png";
import Group from "../assets/BuyIllustrations/group.png";
import Diamond from "../assets/BuyIllustrations/diamond (1) 1.png";
import PaymentHandler from "../utilities/PaymentHandler";
import useUser from "../hook/useUser";
import { useGetProfile } from "../pages/Profile";
import { AiOutlineStock } from "react-icons/ai";
import { FaHandHoldingDollar } from "react-icons/fa6";
import AffordableImg from "../assets/BuyIllustrations/affordable.png"
import GrowthImg from "../assets/BuyIllustrations/—Pngtree—growth icon_3700448.png"

const Price = ({handlePayment}) => {
  const {
    data: userdata,
    isLoading: userLoading,
    isAuthenticated: auth,
  } = useUser();
  const userId = userdata?.id;

  const { data, isLoading } = useGetProfile(userId);

  return (
    <>
      <div className="lg:pt-12 sm:flex sticky justify-center items-center">
        {/* <div className="flex gap-3">
          <h1 className="text-[#22A699] font-bold text-xl lg:text-3xl md:text-2xl">
            Pricing
          </h1>
          <div className="mt-4">
            <img src={DownArrow}></img>
          </div>
        </div> */}
        <div className="breakpointDashboard--container">
          <div className="border-[3px]  md:w-48 w-60 mx-auto sm:w-44 text-center border-[#7FBF95] rounded-2xl pb-6 pt-2 px-2">
            <div>
              <span className="text-2xl text-[#22A699] font-semibold inline-block mt-2">
                Rs 699/-
              </span>
              <hr className="border-neutral-300 mt-1" />
              <h1 className="font-semibold text-[0.9rem] mt-3">
                1 Mock Interview
              </h1>
              <div className="text-center mt-4">
               <img className="mx-auto w-14" src={AffordableImg}></img>
              </div>
              <div className="w-full mt-3">
                <button name="mock interview" onClick={(e)=> handlePayment(e, 699, userdata)} className="w-full py-2 px-5 duration-200 ease-in-out hover:bg-[#329288] hover:shadow-lg hover:shadow-neutral-300 rounded-full text-white bg-[#22A699] font-semibold">
                  Buy Now
                </button>
              </div>
            </div>
          </div>

          <div className="xl:-translate-y-8 sm:w-max mx-auto">
            <div className="">
              <div className="mx-auto py-2 w-32 rounded-full text-white text-center bg-black">
                <p>Max Value!</p>
              </div>
            </div>
            <div className=" mt-4 h-[15rem] md:h-[17rem] bg-[#7C8CC5] text-center md:w-48 w-60 rounded-2xl px-6 flex items-center justify-center">
              <div className="text-white">
                <h1 className="font-semibold text-2xl">Rs 2399/-</h1>
                <hr className="mt-2 border-neutral-200" />
                <div className="text-center mt-2 px-2">
                  <p className="text-white font-medium text-[0.9rem]">5 Mock Interviews</p>
                </div>
                <div className="text-center mt-4">
                  <img className="mx-auto w-14" src={GrowthImg}></img>
                </div>
                <div className="w-full mt-6">
                  <button name="combo(2 mock interview + 1 group discussion)" onClick={(e)=> handlePayment(e, 2399, userdata)} className="w-full py-2 px-5 shadow-xl rounded-full bg-[#425166] font-semibold text-[#DBFFEF] duration-200 ease-in-out hover:bg-[#2b3a4e] hover:shadow-xl">
                    Buy Now
                  </button>
                </div>
              </div>
            </div>
            <div className="text-center px-4 sm:mt-3">
              <p className="text-2xl text-[#22A699] font-bold">Save 40%</p>
            </div>
          </div>

          <div className="border-[3px] text-center mx-auto sm:w-44 border-[#7FBF95] rounded-2xl  md:w-48 w-60 pb-6 pt-2 px-2">
            <div>
              <span className="text-2xl text-[#22A699] font-semibold inline-block mt-2">
                Rs 999/-
              </span>
              <hr className="border-neutral-300 mt-1" />
              <h1 className="font-semibold text-[0.9rem] mt-3">
                2 Mock Interviews
              </h1>
              <div className="text-center mt-4">
                <p className="text-4xl">🚀</p>
              </div>
              <div className="w-full mt-3">
                <button name="group discussion" onClick={(e)=> handlePayment(e, 999, userdata)} className="w-full py-2 px-5 duration-200 ease-in-out hover:bg-[#329288] hover:shadow-lg hover:shadow-neutral-300 rounded-full text-white bg-[#22A699] font-semibold">
                  Buy Now
                </button>
                {/* <a href="#_" class="relative inline-block text-lg group">
                      <span class="relative z-10 block px-5 py-3 overflow-hidden font-medium leading-tight text-gray-800 transition-colors duration-300 ease-out border-2 border-[#22A699] rounded-full group-hover:text-white">
                      <span class="absolute inset-0 w-full h-full px-5 py-3 rounded-full bg-gray-50"></span>
                      <span class="absolute left-0 w-48 h-48 -ml-2 transition-all duration-300 origin-top-right -rotate-90 -translate-x-full translate-y-12 bg-[#22A699] group-hover:-rotate-180 ease"></span>
                        <span class="relative">Buy now</span>
                        </span>
                      <span class="absolute bottom-0 right-0 w-full h-12 -mb-1 -mr-1 transition-all duration-200 ease-linear bg-[#22A699] rounded-full group-hover:mb-0 group-hover:mr-0" data-rounded="rounded-full"></span>
                    </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Price;
