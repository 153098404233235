import { useMutation, useQueryClient } from "@tanstack/react-query";
import SignupApi from "../api/SignupApi";
import { supabase } from "../api/supabase";
import toast from "react-hot-toast";
import { Navigate, redirect, useNavigate } from "react-router-dom";
import { loginApi } from "../api/loginApi";
export default function useSignup() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate: signup, isLoading } = useMutation({
    mutationFn: SignupApi,
    onSuccess: async (data) => {
      // localStorage.setItem("login", true);
      queryClient.setQueryData(["userdata"], data.user);
      toast.success("Account has been created successfully!");
      // console.log("signup api", data.user);
      navigate("/dashboard/main");
      // const data2 = await loginapi(email, password);
      // if (data2) {
      //   console.log("navigating to dashboard");
      //   navigate("/dashboard");
      // }
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  return { signup, isLoading };
}

export async function loginapi({ email, password }) {
  let { data, error } = await supabase.auth.signInWithPassword({
    email: email,
    password: password,
  });
  // console.log("successfully logged in", data);
  return data;
}

export async function nav(navigate) {
  navigate("/dashboard/main");
  // console.log("navigation");
}
