import React from "react";
import { FaQuoteLeft } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import Image from "../../utilities/Image";

const Testimonial = ({name, testimonial, linkedIn, img, college}) => {
  return (
    <div className="px-2 lg:px-6 ">
      <div>
        <div className="lg:w-72 w-full mx-auto">
          <Image
            className="rounded-2xl"
            src={
              img
              // hear?.filter((i) => i.imagename == "hearfromus_1")[0]
              //   ?.imageurl
            }
            alt="hear from us"
          />
        </div>
        <div className="text-center">
          <h1 className="mt-4 font-semibold text-lg">{name} <span className="font-normal">({college})</span> </h1>
          {/* <p>{college}</p> */}
          <a target="_blank" href={linkedIn}>
            <div className="w-6 h-6 rounded-full mx-auto mt-2 items-center cursor-pointer flex justify-center bg-white shadow-lg">
              <FaLinkedinIn className="mx-auto text-sm text-sky-400" />
            </div>
          </a>
        </div>
        <div className="mt-4 w-full text-center">
          <div>
            <FaQuoteLeft className="mx-auto text-2xl" />
          </div>
          <p className="md:text-xl mt-4 md:leading-[1.7rem]">{testimonial}</p>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
