import React, { useContext } from "react";
// import Is from '../assets/Logos/InstSaveLogo (1).png'
// import footer from '../assets/footer/Footer img (1).png'
import { refContext } from "../store/ContextProvider";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import getHome from "../api/getHomepage";
import { SocialIcon } from "react-social-icons/component";
import "react-social-icons/instagram";
import "react-social-icons/linkedin";
import "react-social-icons/youtube";
import "react-social-icons/facebook";

const Footer = () => {
  const refCtx = useContext(refContext);
  const navigate = useNavigate();
  const { data, isLoading, error } = useQuery({
    queryKey: ["home"],
    queryFn: getHome,
  });

  return (
    <footer className="bg-[#CCEEDF] py-12  bg-no-repeat bg-contain bg-right-bottom">
      <div className="container mx-auto flex flex-wrap w-[90%] justify-between max-w-7xl">
        <div className="w-full md:w-1/2 lg:w-1/4 mb-8">
          <div className="w-40">
            <img
              src={
                data?.filter((i) => i.imagename == "interestsave")[0]?.imageurl
              }
              alt="interestsave"
            ></img>
          </div>
          <p className="mt-2">
            We are on a mission to reduce the financial burden of higher
            education for students.
          </p>
        </div>

        <div className="w-full md:w-1/2 lg:w-1/4 mb-8">
          <h2 className="text-xl text-black font-bold mb-4">Quick Links</h2>
          <ul className="list-none p-0">
            <li
              className="cursor-pointer"
              onClick={() => refCtx.scrollToView(refCtx.aboutusRef, navigate)}
            >
              <a className="text-gray-700 hover:underline duration-150 hover:text-black">
                How We Started?
              </a>
            </li>
            {/* <li><a href="#" className="text-gray-700 hover:underline duration-150 hover:text-black">Careers</a></li> */}
            <li
              onClick={() => refCtx.scrollToView(refCtx.faqsRef, navigate)}
              className="cursor-pointer"
            >
              <a className="text-gray-700 hover:underline duration-150 hover:text-black">
                FAQs
              </a>
            </li>
            <li
              className="text-gray-700 hover:underline duration-150 hover:text-black"
            >
              <Link to="/contact">Contact Us</Link>
            </li>
            <li className="text-gray-700 hover:underline duration-150 hover:text-black">
              <Link to="/privacypolicy">Privacy Policy</Link>
            </li>
            <li className="text-gray-700 hover:underline duration-150 hover:text-black">
              <Link to="/refundpolicy">Refund Policy</Link>
            </li>
            <li className="text-gray-700 hover:underline duration-150 hover:text-black">
              <Link to="/cancellationpolicy">Cancellation Policy</Link>
            </li>
            <li className="text-gray-700 hover:underline duration-150 hover:text-black">
              <Link to="/termsandcondition">Terms and Condition</Link>
            </li>
          </ul>
        </div>

        <div className="w-full md:w-1/2 lg:w-1/4 mb-8">
          <h2 className="text-xl text-black font-bold mb-4">Socials</h2>
          <div className="flex gap-2">
            <a target="_blank" href="https://www.youtube.com/@InterestSave">
              <SocialIcon
                style={{ width: "30px", height: "30px" }}
                network="youtube"
              />
            </a>
            <a target="_blank" href="https://instagram.com/interestsave">
              <SocialIcon
                style={{ width: "30px", height: "30px" }}
                network="instagram"
              />
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/interestsave/"
            >
              <SocialIcon
                style={{ width: "30px", height: "30px" }}
                network="linkedin"
              />
            </a>
            <a
              target="_blank"
              href="https://www.facebook.com/people/InterestSave/61553162009605/"
            >
              <SocialIcon
                style={{ width: "30px", height: "30px" }}
                network="facebook"
              />
            </a>
          </div>
        </div>
        {/* 
        <div className="w-full md:w-1/2 lg:w-1/4 mb-8">
          <h2 className="text-xl text-black font-bold mb-4">
            Subscribe to Newsletter
          </h2>
          <div className="flex ">
            <input
              type="email"
              placeholder="Your email"
              className="bg-gray-100 text-black px-4 w-full py-2 rounded-l focus:outline-none"
            />
            <button className="bg-gray-900 w-max text-white px-6 py-2 rounded-r">
              <IoSend />
            </button>
          </div>
        </div> */}
      </div>

      <div className="border-t border-gray-600 mt-8 py-4">
        <div className="mx-auto text-center">
          <p className="text-gray-900">
            &copy; 2023 InterestSave. All rights reserved.
          </p>
          <p className="mt-1 font-medium">Necred Technologies Pvt. Ltd.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
