import { useForm } from "react-hook-form";
import useForgotPasswordReset from "../hook/useForgotPasswordReset";
import img from '../assets/ee56534d35cccaf58479b3ba74a75908.png'
import { useEffect } from "react";
import Image from "../utilities/Image";
export default function ForgotPassword() {
  const { register, handleSubmit, formState, getValues, reset } = useForm();
  const { errors } = formState;
  const { forgotpassword, isUpdating } = useForgotPasswordReset();

  function onSubmit({ email }) {
    console.log("Form Called");
    console.log(email);
    forgotpassword(email);
  }


  return (
    <div className="flex flex-col lg:flex-row items-center justify-center bg-gray-50 py-24 px-4 sm:px-6 lg:px-8 gap-4 lg:gap-8">
      <div className="max-w-md w-full space-y-8 order-2">
        <div>
          <h2 className="mt-6 text-left text-3xl font-extrabold text-gray-900">
            Forgot Password? <span className="text-[#4DB7AC]">Relax!</span> 
          </h2>
          <p className="mt-2">Don’t worry! It happens. Please enter the email associated with your account and we will send the password reset link in this email.</p>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6 ">
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email">
                Email address
              </label>
              <p className="text-red-400">{errors?.email?.message}</p>
              <input
                id="email"
                name="email"
                type="email"
                required
                {...register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Entered email is not valid",
                  },
                })}
                className="relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:border-[#22a699] mt-2 focus:z-10 sm:text-sm"
                placeholder="Email address"
              />
            </div>
          </div>
          <div>
            <button
              className="relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white duration-200 bg-[#22a699]/80 hover:bg-[#22a699] "
            >
              Send Reset Link
            </button>
          </div>
        </form>
      </div>
      <div className="w-80 order-1">
          <div>
            <Image src={img} />
          </div>
      </div>
    </div>
  );
}
