import { supabase } from "./supabase";
export async function updatePassword(password) {
  // console.log("api password", password);
  const { data, error } = await supabase.auth.updateUser({
    password: password,
  });

  if (error) throw new Error(error.message);

  return data;
}
