import React, { useContext, useEffect, useState } from "react";
import ReactStars from "react-stars";
import Image from "../../utilities/Image";
import ChatImg from "../../assets/BuyIllustrations/chat.png";
import TeachImg from "../../assets/BuyIllustrations/teach.png";
import EnrollImg from "../../assets/BuyIllustrations/RefundProcess/enroll 1.png";
import InterviewImg from "../../assets/BuyIllustrations/RefundProcess/interview 1.png";
import CashImg from "../../assets/BuyIllustrations/RefundProcess/cash.png";
import { GoArrowRight } from "react-icons/go";
import Slider from "react-slick";
import useUser from "../../hook/useUser";
// import { useGetProfile } from "../../pages/Profile";
import LoaderSpinner from "../../components_/Loader";
import { refContext } from "../../store/ContextProvider";
import PaymentHandler from "../../utilities/PaymentHandler";

const Heading = () => {
  return (
    <div className="bg-[#E3F4F4] p-6 md:px-28 md:py-12">
      <div>
        <h1 className="text-[22px] md:text-[32px] tracking-[1px] font-bold">
          Crack Your B-School Admission with our Mock GDPI
        </h1>
      </div>
      <div className="mt-3 flex justify-between items-center">
        <div className="bg-[#50A49952] h-max rounded-md py-2 px-3 flex justify-between items-center gap-4">
          <ReactStars count={5} value={4.5} size="24px" color2="#000000" />
          <p className="text-[10px] md:text-[14px] font-semibold">(4.5/5)</p>
        </div>
        <div className="bg-[#50A49952] rounded-md py-2 px-3 flex justify-between items-center gap-4">
          <div>
            <Image src={ChatImg} className="w-6" />
          </div>
          <p className="text-[10px] md:text-[14px] font-semibold">
            (350+ sessions)
          </p>
        </div>
      </div>
    </div>
  );
};

export const PriceCard = ({
  label,
  discountedPrice,
  price,
  benefits,
  data,
  handlePayment
}) => {
  const ctx = useContext(refContext);

  return (
    <div className="border-[0.1px] border-gray-200 rounded-3xl w-full shadow-md ">
      <div className=" px-5 pt-6 pb-4 ">
        <div className="flex justify-between items-center">
          <div className="bg-[#F5F4F4] rounded-[7px] border-[0.6px] border-[#B2B4B5] px-2 py-1 flex items-center gap-3">
            <Image src={TeachImg} />
            <p className="leading-[18px] tracking-[0.77px] text-[13px] text-[#434343] font-medium">
              {label}
            </p>
          </div>
          <button name={label} onClick={(e)=>handlePayment(e, price)}>
            <div className="border-2 w-full cursor-pointer border-black rounded-full p-[5px]">
              <GoArrowRight name={label} />
            </div>
          </button>
        </div>
        <div className="pt-7 px-3">
          {benefits.map((benefit) => (
            <div className="flex items-center gap-3">
              <div className="w-2 h-2 rounded-full border-[1px] border-black"></div>
              <p className="leading-[18px] tracking-[0.77px] text-[13px] text-[#434343]">
                {benefit}
              </p>
            </div>
          ))}
        </div>
      </div>
      <hr className="border-neutral-300 mt-10" />
      <div className="py-6 px-6 flex flex-row flex-wrap gap-2 items-center justify-between">
        <div className="bg-[#50A499] text-white rounded-[14px]">
          <button
            name={label}
            onClick={(e)=>handlePayment(e, price)}
            className="w-full py-2 px-6 text-[16px] font-semibold"
          >
            Buy Now
          </button>
        </div>
        <div className="bg-[#F5F4F4] border-[0.8px] border-[#B2B4B5] flex items-center gap-3 rounded-lg px-3 py-1">
          <p className="leading-[22px] tracking-[0.77px] line-through text-[12px]">
            ₹ {discountedPrice}
          </p>
          <p className="text-[16px] font-bold leading-[22px] tracking-[0.77px]">
            ₹ {price}
          </p>
        </div>
      </div>
    </div>
  );
};

const Buy = () => {
  const {
    data: userdata,
    isLoading: userLoading,
    isAuthenticated: auth,
  } = useUser();

  const [loading, setLoading] = useState(false);
  const handlePayment = (e, price) => {
    setLoading(true);
    PaymentHandler(e, price, userdata, setLoading);
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    appendDots: (dots) => (
      <div
        style={{
          height: "14px",
        }}
      >
        <ul style={{ marginTop: "16px" }}> {dots} </ul>
      </div>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>       
          <LoaderSpinner loading={loading || userLoading}>
            <>
              <Heading />
              <div className="py-8 px-5 md:px-28">
                <div className="tracking-[0.7px] leading-[26px] text-[15px] md:text-[1rem] font-medium text-[#434343]">
                  <p>
                    Get brutally honest feedback on your mock interview and
                    detailed feedback by IIM grads, who have actually cracked
                    the exam. Gain more clarity and make impactful changes to
                    crack the IIM interview.
                    <br />
                    <br />
                    <p className="mt-2">
                      Having been through the experiences of MBA students
                      ourselves, we understand the challenges you face. Allow us
                      to be your mentors, guiding you through interview
                      preparations and empowering you to triumph in your MBA
                      admissions journey.
                    </p>
                  </p>
                </div>
                <div className="py-12 px-8 tracking-[0.8px] leading-[18px] text-[13px] md:text-[16px] font-bold text-[#000] ">
                  <p>
                    Bonus: If you clear the Interview Round of IIMs and top
                    B-schools, we will assist you in the Loan Process as well
                    with the banks for FREE !
                  </p>
                </div>
                <div>
                  <h1 className="leading-[26px] tracking-[0.8px] text-[20px] font-bold">
                    Who is this for?
                  </h1>
                  <ul className="px-6 mt-2 font-[13px] list-disc text-[15px] leading-[22px] tracking-[0.77px] text-[#434343]">
                    <li className="marker:text-neutral-300">
                      MBA candidates who want to improve their communication
                      skills, confidence, and personality before facing the
                      actual GDPI rounds
                    </li>
                    <li className=" marker:text-neutral-300">
                      CAT aspirants aiming for top B-schools, looking to excel
                      in GDPI for better admission chances.
                    </li>
                    <li className=" marker:text-neutral-300">
                      CAT aspirants who want to get a feel of the GDPI process
                      and prepare themselves for the actual interviews in
                      advance.
                    </li>
                    <li className=" marker:text-neutral-300">
                      Aspirants who have diverse backgrounds and want to
                      showcase their unique personality and potential in the
                      GDPI round.
                    </li>
                  </ul>
                </div>
                <div className="mt-12">
                  <h1 className="leading-[26px] tracking-[0.8px] text-[20px] font-bold">
                    What You’ll get out of this?
                  </h1>
                  <ul className="px-6 mt-2 font-[13px] list-disc text-[15px] leading-[22px] tracking-[0.77px] text-[#434343]">
                    <li className="marker:text-neutral-300">
                      Get brutal honest feedback on your Mock Interviews.
                    </li>
                    <li className=" marker:text-neutral-300">
                      Personalized mentorship by IIM grads who have actually
                      cracked the Interview.
                    </li>
                    <li className=" marker:text-neutral-300">
                      WAT Preparation.
                    </li>
                    <li className=" marker:text-neutral-300">
                      You would be able to test your preparedness and
                      performance in a realistic and competitive setting.
                    </li>
                    <li className=" marker:text-neutral-300">
                      Identification of areas for improvement before actual GDPI
                      rounds.
                    </li>
                  </ul>
                </div>
                <div className="mt-12">
                  <h1 className="leading-[26px] tracking-[0.8px] text-[20px] font-bold">
                    Other Benefits:
                  </h1>
                  <ul className="px-6 mt-2 font-[13px] list-disc text-[15px] leading-[22px] tracking-[0.77px] text-[#434343]">
                    <li className="marker:text-neutral-300">
                      CV and Profile Building.
                    </li>
                    <li className=" marker:text-neutral-300">
                      100% Refund once you get an offer letter from IIMs.
                    </li>
                    <li className=" marker:text-neutral-300">
                      Transcript of previous year interviews of present IIM
                      grads.
                    </li>
                    <li className=" marker:text-neutral-300">
                      Assist in securing your Education loan with banks for
                      FREE.
                    </li>
                  </ul>
                </div>
                <div className="mt-12 flex items-center justify-center gap-4 flex-col lg:flex-row">
                  <PriceCard
                    label="Single Pack"
                    discountedPrice="999"
                    price="699"
                    benefits={["1 Mock Interview / GD", "1 Person"]}
                    data={userdata}
                    handlePayment={handlePayment}
                  />
                  <PriceCard
                    label="Most Purchased"
                    discountedPrice="1399"
                    price="999"
                    benefits={["2 Mock Interviews / GDs", "1 Person"]}
                    data={userdata}
                    handlePayment={handlePayment}
                  />
                  <PriceCard
                    label="Super Value Pack"
                    discountedPrice="3499"
                    price="2399"
                    benefits={["5 Mock Interviews / GDs", "2 Persons"]}
                    data={userdata}
                    handlePayment={handlePayment}
                  /> 
                  {/* <PriceCard
                    label="Ultra Saver Pack"
                    discountedPrice="2999"
                    price="2999"
                    benefits={["9 Mock Interviews / GDs", "3 Persons"]}
                    data={userdata}
                    handlePayment={handlePayment}
                  /> */}
                </div>
                <div className="mt-16">
                  <div className="text-center text-[24px] leading-[26px] font-bold ">
                    <h1>How 100% Refund Works</h1>
                  </div>
                  <div className="mt-14 px-6">
                    <div className="flex gap-2 items-center md:justify-center">
                      <div className="sm:w-40 w-80 ">
                        <Image src={EnrollImg} className="w-full" />
                      </div>
                      <div>
                        <div className="flex items-center gap-2 leading-[26px] tracking-[0.8px] font-semibold text-lg ">
                          <p className="text-3xl">1.</p>
                          <h1>Enroll for Mock Interview/GD</h1>
                        </div>
                        <p className="mt-2 text-[15px] leading-[22px] tracking-[0.77px] font-[400]">
                          Enroll to test, analyse and improve your interview
                          skills from IIM Grads.
                        </p>
                      </div>
                    </div>

                    <div className="flex gap-2 items-center md:justify-center mt-16">
                      <div className="sm:w-40 w-80  order-2">
                        <Image src={InterviewImg} className="w-full" />
                      </div>
                      <div>
                        <div className="flex items-center gap-2 leading-[26px] tracking-[0.8px] font-semibold text-lg ">
                          <p className="text-3xl">2.</p>
                          <h1>Sit for Mock Interview/GD</h1>
                        </div>
                        <p className="mt-2 text-[15px] leading-[22px] tracking-[0.77px] font-[400]">
                          Sit for Mock Interview and get brutal honest feedback
                          and tips to crack the interview.
                        </p>
                      </div>
                    </div>

                    <div className="flex gap-2 items-center md:justify-center mt-16">
                      <div className="sm:w-40 w-80 ">
                        <Image src={CashImg} className="w-full" />
                      </div>
                      <div>
                        <div className="flex items-center gap-2 leading-[26px] tracking-[0.8px] font-semibold text-lg ">
                          <p className="text-3xl">3.</p>
                          <h1>Get 100% Refund</h1>
                        </div>
                        <p className="mt-2 text-[15px] leading-[22px] tracking-[0.77px] font-[400]">
                          Crack the interview, secure admission, and get 100%
                          fee refund with free loan assistance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-12">
                  <h1 className="leading-[26px] tracking-[0.8px] text-[22px] font-bold">
                    About us:
                  </h1>
                  <p className=" mt-2 font-[13px] list-disc text-[15px] leading-[22px] tracking-[0.77px] text-[#434343]">
                    InterestSave, an IIM Alumni initiative, is more than just a
                    platform; we're your dedicated seniors on a mission to
                    empower ambitious MBA aspirants to secure a seat in top
                    Indian B-schools.
                    <br />
                    <br />
                    We know how challenging and stressful it can be to prepare
                    for the CAT exam and the subsequent GDPI rounds. That's why
                    we offer you the best guidance and mentorship from our
                    experienced team of IIM graduates, who have cracked the top
                    B-schools recently and understand the current trend of CAT
                    interview and could provide better insights and strategy
                    than others.
                    <br />
                    <br />
                    Our mock GDPI sessions are designed to simulate the
                    real-life scenarios and questions that you may face in your
                    actual interviews. We provide you with personalized feedback
                    and tips to improve your performance and confidence. We also
                    help you with your resume, SOP, and other application
                    documents to make sure you stand out from the crowd.
                    <br />
                    <br />
                    Whether you are aiming for IIM-A, B, C, or any other
                    prestigious B-school, we are here to support you throughout
                    your journey. Join InterestSave today and get ready to
                    achieve your MBA dreams!”
                  </p>
                </div>

                <div className="mt-12">
                  <h1 className="leading-[26px] text-center tracking-[0.8px] text-[20px] font-[500]">
                    See what your friends at{" "}
                    <span className="font-semibold text-[#50A499] italic">
                      Top B-School
                    </span>{" "}
                    says
                  </h1>
                  <div className=" mt-12 font-[13px] px-4 list-disc text-[15px] leading-[22px] tracking-[0.77px] text-[#434343]">
                    <Slider {...settings}>
                      <div className="border-2 border-black rounded-xl py-4 px-2 text-center">
                        <div>
                          <p className="text-[0.95rem]">
                            "During my MBA journey, I had so many questions and
                            queries. InterestSave's Mock PI with feedback &
                            interaction session that helps aspirants get their
                            queries answered by their potential seniors from
                            their Dream B-Schools will give a lot of value to
                            the aspirants!"
                          </p>
                          <p className="mt-2 font-semibold">
                            Manoj, IIM Bangalore
                          </p>
                        </div>
                      </div>
                      <div className="border-2 border-black rounded-xl py-4 px-2 text-center">
                        <div>
                          <p className="text-[0.95rem]">
                            "At some of the interviews, I was asked questions
                            regarding policy making. I was completely taken
                            aback by such questions! InterestSave's Mock PI with
                            people pursuing MBA at various top B-Schools will
                            help aspirants to gain real live insights and the
                            pressure of a top B-School interview!"
                          </p>
                          <p className="mt-2 font-semibold">
                            Sunny B, IIM Jammu
                          </p>
                        </div>
                      </div>
                      <div className="border-2 border-black rounded-xl py-4 px-2 text-center">
                        <div>
                          <p className="text-[0.95rem]">
                            "MBA life is full of rigor and it starts with the
                            PIs & GDs. In some of my PIs I grille a lot. Having
                            InterestSave as your Mock PI buddy wherein
                            candidates will have mocks with students who have
                            taken the actual interview just a year back will be
                            very helpful and insightful for the candidates."
                          </p>
                          <p className="mt-2 font-semibold">
                            Garima, IIM Raipur
                          </p>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
          )
        </>
        </LoaderSpinner>
    </div>
  );
};

export default Buy;
