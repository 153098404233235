import React from "react";
// import BenefitImg from "../assets/benefits/Benefits.png";
// import Benefit1 from "../assets/benefits/12. business-deal-1.png";
// import Benefit2 from "../assets/benefits/39. payment-processed-1.png";
// import Benefit3 from "../assets/benefits/48. strategy-6.png";
import Benefit4 from "../assets/25. having-fun-1.png"
import Benefit5 from "../assets/44. smart-people-1.png"

import Blob from "../ui/Blob";
import BlobStatic from "../ui/BlobStatic";
import Image from "../utilities/Image";
const Benefits = ({ refCtx, benefit, isLoading }) => {
  return (
    <div ref={refCtx.benefitRef} className="mt-12 lg:mt-20">
      <section className="text-center">
        <h1 className="lg:text-5xl md:text-4xl text-3xl font-semibold inline-block"><span className="text-[#22A699]">Benefits</span> for Members</h1>
      </section>
      <div className="mt-6 lg:mt-10 py-16 pb-20 bg-gradient-to-b from-[#ffffff] to-[#bbefe9]">
        <section className=" flex lg:bg-BenefitHero bg-contain bg-no-repeat ">
          <div className="max-w-7xl relative w-[90%] mx-auto flex flex-col lg:flex-row gap-6">
          {/* Benefit 1 */}
            <div className="flex flex-row lg:flex-col text-left lg:text-center gap-2 items-center flex-1 align-baseline">
              <div className="order-2 lg:order-1 mx-auto lg:w-[15rem] md:w-[15rem] w-[35rem]">
                <Image
                  src={
                    benefit?.filter((i) => i.imagename == "benefits_1")[0]
                      ?.imageurl
                  }
                  alt="benefit"
                />
              </div>
              <div className="order-1 lg:order-2">
                <h1 className="text-4xl  text-[#22A699] font-bold">1</h1>
                <h2 className="text-2xl text-neutral-600 font-bold">No Charges</h2>
                <p className="lg:mx-auto  mt-2 text-[1.3rem] lg:text-[1.1rem] md:text-[1.2rem]">
                  Charges, no obligation, no credit verification required. There’s no commitment/push.
                </p>
              </div>
            </div>
            {/* Benefit 2 */}
            <div className="flex relative flex-row lg:flex-col gap-2 text-right lg:text-center items-center flex-1 align-baseline">
              {/* <Blob /> */}
              <BlobStatic size={120} />
              <div className=" mx-auto order-1 lg:order-1 lg:w-[15rem] md:w-[15rem] w-[35rem]">
                <Image
                  src={
                    benefit?.filter((i) => i.imagename == "benefits_2")[0]
                      ?.imageurl
                  }
                  alt="benefit"
                />
              </div>
              <div className="order-2 lg:order-1">
                <h1 className="text-4xl  text-[#22A699] font-bold">2</h1>
                <h2 className="text-2xl text-neutral-600 font-bold">
                Best Interest Rate in India (Guaranteed)
                </h2>
                <p className="mt-2 text-[1.3rem] lg:text-[1.1rem] md:text-[1.2rem]">
                InterestSave Members will receive guidance and assist in getting the best proposal for MBA loan from Banks for FREE. 
                </p>
              </div>
            </div>
            {/* Benefit 3 */}
            <div className="flex relative flex-row lg:flex-col text-left lg:text-center items-center flex-1 align-baseline">
              {/* <Blob /> */}
              <div className=" mx-auto order-2 lg:order-1 lg:w-[15rem] md:w-[15rem] w-[35rem]">
                <Image
                  src={
                    benefit?.filter((i) => i.imagename == "benefits_3")[0]
                      ?.imageurl
                  }
                  alt="benefit"
                />
              </div>
              <div className="order-1 lg:order-2">
                <h1 className="text-4xl  text-[#22A699] font-bold">3</h1>
                <h2 className="text-2xl text-neutral-600 font-bold">Free Access to Student Community </h2>
                <p className="lg:mx-auto mt-2 text-[1.3rem] lg:text-[1.1rem] md:text-[1.2rem]">
                Get access to InterestSave community forum where you can ask your queries and interact with your peers/aspirants
                </p>
              </div>
            </div>          
          </div>
        </section>
        <section className="flex mt-6">
          <div className="max-w-3xl relative w-[90%] mx-auto flex flex-col lg:flex-row gap-12">
          {/* Benefit 4 */}
            <div className="flex flex-row lg:flex-col text-right lg:text-center items-center flex-1 align-baseline">
              <div className="order-1 lg:order-2  mx-auto lg:w-[13rem] md:w-[25rem] w-[55rem]">
                <Image
                  src={
                    Benefit4
                    // benefit?.filter((i) => i.imagename == "benefits_1")[0]
                    //   ?.imageurl
                  }
                  alt="benefit"
                />
              </div>
              <div className="order-1 lg:order-2">
                <h1 className="text-4xl  text-[#22A699]  font-bold">4</h1>
                <h2 className="text-2xl text-neutral-600 font-bold">Value Learning</h2>
                <p className="mt-2 text-[1.3rem] lg:text-[1.1rem] md:text-[1.2rem]">
                Get brutal feedback on your mock GDPI. 1-1 personalized mentorship from IIM Alumni.
                </p>
              </div>
            </div>
            {/* Benefit 5 */}
            <div className="flex relative flex-row lg:flex-col gap-4 text-left lg:text-center items-center flex-1 align-baseline">
              {/* <Blob /> */}
              {/* <BlobStatic size={120} /> */}
              <div className=" mx-auto order-2 lg:order-1 lg:w-[13rem] md:w-[15rem] w-[15rem]">
                <Image
                  src={
                    Benefit5
                    // benefit?.filter((i) => i.imagename == "benefits_2")[0]
                    //   ?.imageurl
                  }
                  alt="benefit"
                />
              </div>
              <div className="order-1 lg:order-2 flex-1">
                <h1 className="text-4xl text-[#22A699]  font-bold">5</h1>
                <h2 className="text-2xl text-neutral-600 font-bold">
                Rate Match Guarantee
                </h2>
                <p className="mt-2 text-[1.3rem] lg:text-[1.1rem] md:text-[1.2rem]">
                We trust our deals so much that if you come across a better deal from another lenders/competitors, let us know.
                </p>
              </div>
            </div>

          </div>
        </section>
      </div>
    </div>
  );
};

export default Benefits;
