import React, { useEffect, useState } from "react";
import Image from "../utilities/Image";
import UpiImg from "../assets/upi.png"
import { IoMdCopy } from "react-icons/io";
import PayImg from "../assets/BuyIllustrations/pay.png"

const Pay = () => {
  const [currentStep, setStep] = useState(1);
  const [paymentData, setPaymentData] = useState({
    transactionId:"",
    name:"",
    email:"",
    phone:""
  })

  const handleCopyClick = (text) => {
    navigator.clipboard.writeText(text);
  }


  const onNext = () => {
    setStep(currentStep+1);
  }

  const onPrevious = () => {
    setStep(currentStep-1);
  }

  const handleSubmit = (e) => {
    console.log(paymentData);
    alert("Payment Successful")
  }

  const handleChange = (e) => {
    setPaymentData({...paymentData,[e.target.name]:e.target.value})
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const MultiStep = () => {
    switch(currentStep){
      case 1:
        return(
          <section>
            {/* Step 1 */}
            <div>
              <header className="flex md:items-center gap-2 md:gap-0 md:flex-row flex-col justify-between">
                <div className="flex items-center gap-6">
                  <p className="text-xl font-semibold">Step 1 :</p>
                  <p className="text-xl text-neutral-400">Pay Now With</p>
                </div>
                <div className="md:text-lg font-bold">
                  (Amount: ₹610.08)
                </div>
              </header>
              <section className="flex flex-col md:flex-row items-center md:items-stretch justify-center gap-6 mt-12">
                <div className="text-center">
                  <h1 className="text-lg md:text-2xl font-bold">QR CODE</h1>
                  <div className="mt-6">
                    <Image src={UpiImg} className="w-80" />
                  </div>
                </div>
                <div className="flex items-center md:flex-col gap-8 md:gap-14 md:mt-14 ">
                  <hr className="border-[1px] border-neutral-500 md:rotate-90 w-24 " />
                  <p className="text-lg">OR</p>
                  <hr className="border-[1px] border-neutral-500 md:rotate-90 w-24 " />
                </div>
                <div className="text-center">
                  <h1 className="text-lg md:text-2xl font-bold">Our UPI ID</h1>
                  <div onClick={()=>handleCopyClick("MSNECREDTECHNOLOGIESPRlVATELIMlTED.eazypay@icici")} className="md:mt-[70px] mt-4 py-3 px-6 text-sm border-neutral-300 border-[2px] rounded-lg flex items-center gap-3 flex-col sm:flex-row flex-wrap">
                    <p className=" break-all ">MSNECREDTECHNOLOGIESPRlVATELIMlTED.eazypay@icici</p>
                    <IoMdCopy className="text-xl cursor-pointer " />
                  </div>
                </div>
              </section>
            </div>
            {/* Step 2 */}
            <div className="mt-12">
              <header className="flex md:items-center gap-4 md:gap-0 md:flex-row flex-col justify-between">
                <div className="flex items-center gap-6 flex-1">
                  <p className="text-xl font-semibold">Step 2 :</p>
                  <p className="text-xl text-neutral-400">Enter Transaction ID</p>
                </div>
                <div className="md:text-lg flex-1">
                  <input autoComplete="off" autofi value={paymentData.transactionId} onChange={handleChange} name="transactionId" required className="py-2 px-4 w-full border-[1px] focus:outline-neutral-400 border-neutral-400 rounded-lg " placeholder="12 Digit Numbers" />
                </div>
              </header>
            </div>
            {/* Next Button */}
            <div className="md:mt-24 mt-8 text-center">
              <button disabled={paymentData.transactionId.length < 12} onClick={onNext} className="py-2 px-12 text-lg bg-[#22A699] disabled:bg-[#92cdc7] disabled:cursor-not-allowed rounded-lg text-white">Next</button>
            </div>
          </section>
        )

      case 2:
        return(
          <section>
            <header className="flex md:items-center gap-2 md:gap-0 md:flex-row flex-col justify-between">
                <div className="flex items-center gap-6">
                  <p className="text-xl font-semibold">Step 3 :</p>
                  <p className="text-xl text-neutral-400">Personal Details</p>
                </div>
            </header>
            <div className="max-w-[60rem] mx-auto w-[95%] mt-6">
              <div>
                <label className="block text-lg md:text-xl" for="name" >Name</label>
                <input value={paymentData.name} onChange={handleChange} id="name" name="name" className="py-2 w-full px-3 mt-2 rounded-lg outline-none border-[1px] border-neutral-300 " />
              </div>
              
              <div className="mt-6">
                <label className="block text-lg md:text-xl" for="email" >Email</label>
                <input value={paymentData.email} onChange={handleChange} id="email" name="email" className="py-2 w-full px-3 mt-2 rounded-lg outline-none border-[1px] border-neutral-300 " />
              </div>
              
              <div className="mt-6">
                <label className="block text-lg md:text-xl" for="phone" >Phone No.</label>
                <input value={paymentData.phone} onChange={handleChange} id="phone" name="phone" className="py-2 w-full px-3 mt-2 rounded-lg outline-none border-[1px] border-neutral-300 " />
              </div>
            </div>
            <div className="flex justify-between items-center max-w-[60rem] mx-auto w-[95%]">
              <div className="md:mt-24 mt-8 text-center">
                <button disabled={paymentData.transactionId.length < 12} onClick={onPrevious} className="py-2 md:px-12 px-8 text-lg bg-[#22A699] disabled:bg-[#92cdc7] disabled:cursor-not-allowed rounded-lg text-white">Previous</button>
              </div>
              <div className="md:mt-24 mt-8 text-center">
                <button disabled={paymentData.transactionId.length < 12} onClick={handleSubmit} className="py-2 md:px-12 px-8 text-lg bg-[#22A699] disabled:bg-[#92cdc7] disabled:cursor-not-allowed rounded-lg text-white">Submit</button>
            </div>
            </div>
          </section>
        )

      default:
        return null;
    }
  }

  return (
    <div className="max-w-6xl mx-auto w-[95%] py-12 flex items-center justify-center">
      {/* {MultiStep()} */}
      <Image src={PayImg} />
    </div>
  )
};

export default Pay;
