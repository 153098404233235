import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LogoutApi } from "../api/LogoutApi";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function useLogout() {
  //navigate to different page on logout
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    mutate: logout,
    isLoading,
    error,
  } = useMutation({
    mutationFn: LogoutApi,
    onSuccess: () => {
      // console.log("successfully logged out");
      localStorage.setItem("login", false);
      queryClient.removeQueries();
      navigate("/", { replace: true });
    },
    onError: (err) => {
      toast.error("Not able to logout");
    },
  });

  return { logout, isLoading, error };
}
