import React, { useContext } from "react";
import Header from "../components/Header";
import Features from "../components/Features";
import Blob from "../ui/Blob";
import BestLoan from "../components/BestLoan";
import Benefits from "../components/Benefits";
import WhyTrustUs from "../components/WhyTrustUs";
import SaveMoney from "../components/SaveMoney";
import FAQ from "../components/FAQ";
import LearnMode from "../components/LearnMode";
import Podcast from "../components/Podcast";
import HearFromIIMs from "../components/Testimonial/HearFromIIMs";
import SignUp from "./SignUp";
import Login from "./Login";
import { refContext } from "../store/ContextProvider";
import { useQuery } from "@tanstack/react-query";
import getHome from "../api/getHomepage";
import Services from "../components/Services";
import MockInterview from "../components/MockInterview";
import Stats from "../components/Stats";
import YoutubeVideo from "../components/YoutubeVideo";

const Home = ({ benefitRef, podcastsRef, aboutusRef, faqsRef }) => {
  const { data, isLoading, error } = useQuery({
    queryKey: ["home"],
    queryFn: getHome,
  });
  console.log(data);
  const refCtx = useContext(refContext);
  return (
    <div className="py-8">
      <div className="">
        <Header hero={data} isLoading={isLoading} />
        {/* <Features /> */}
        {/* <Blob /> */}
        <Stats loan={data} isLoading={isLoading}  />
        <Services />
        <MockInterview />
        {/* <BestLoan loan={data} isLoading={isLoading} /> */}
        <Benefits refCtx={refCtx} benefit={data} isLoading={isLoading} />
        <WhyTrustUs refCtx={refCtx} trust={data} isLoading={isLoading} />
        <SaveMoney save={data} isLoading={isLoading} />
        <HearFromIIMs refCtx={refCtx} hear={data} isLoading={isLoading} />
        {/* <Podcast refCtx={refCtx} pod={data} isLoading={isLoading} /> */}
        {/* <LearnMode /> */}
        <YoutubeVideo />
        <FAQ refCtx={refCtx} />
      </div>
    </div>
  );
};

export default Home;
