import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import { loginApi } from "../api/loginApi";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

export default function useLogin() {
  const queryClient = useQueryClient();
  //navigate to different page on login
  const navigate = useNavigate();
  const {
    mutate: login,
    isLoading,
    error,
  } = useMutation({
    mutationFn: ({ email, password }) => loginApi({ email, password }),
    onSuccess: (user) => {
      // console.log("uselogin hook", user.user);
      queryClient.setQueryData(["userdata"], user.user);
      navigate("/dashboard/main", { replace: true });
    },
    onError: (err) => {
      toast.error("Either password or email is wrong");
    },
  });

  return { login, isLoading, error };
}
