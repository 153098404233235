import React, { useEffect } from "react";
// import Img from "../assets/accountCreate/login-amico.png";
//new add
import { useForm } from "react-hook-form";
import useSignup from "../hook/useSignup";
import { Link, useNavigate } from "react-router-dom";
import useUser from "../hook/useUser";
import { useQuery } from "@tanstack/react-query";
import getHome from "../api/getHomepage";
import { NavLink } from "react-router-dom";
import Image from "../utilities/Image";

const SignUp = () => {
  //new add
  const {
    data: image,
    isLoading: load,
    error: imageerror,
  } = useQuery({
    queryKey: ["home"],
    queryFn: getHome,
  });
  const { register, formState, getValues, reset, handleSubmit } = useForm();
  const { errors } = formState;
  const { signup, isLoading } = useSignup();

  const { isLoading: loading, isAuthenticated } = useUser();
  const navigate = useNavigate();

  useEffect(
    function () {
      if (isAuthenticated && !loading) {
        navigate("/dashboard/main");
      }

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    [loading, isAuthenticated, navigate]
  );

  function formSubmit({
    firstName,
    lastName,
    email,
    phoneNumber,
    referralCode,
    password,
  }) {
    let referralcode;
    if (!referralCode) {
      referralcode = "0000";
    } else {
      referralcode = referralCode;
    }
    console.log(
      firstName,
      lastName,
      email,
      phoneNumber,
      referralcode,
      password
    );
    signup(
      {
        firstname: firstName,
        lastname: lastName,
        email: email,
        phonenumber: phoneNumber,
        referralcode,
        password,
      },
      {
        onSettled: () => reset(),
      }
    );
  }

  //old
  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, []);
  if (!isAuthenticated && !loading)
    return (
      <div className="">
        <form onSubmit={handleSubmit(formSubmit)}>
          <div className="lg:max-w-full max-w-7xl mx-auto">
            <div className="flex flex-col lg:flex-row ">
              <div className="mx-auto px-[2rem] -mt-2 flex items-center justify-center py-[1rem] text-left flex-1 bg-[#8FC5BE40]">
                <div className="lg:max-w-xl mx-auto mt-4">
                  <div>
                    <h1 className="font-bold text-2xl md:text-3xl ">
                      Let's work together to crack your dream
                      <span className="text-[#22A699] font-bold italic ">
                        {" "}
                        B-school Interview
                      </span>
                    </h1>
                  </div>
                  <h1 className="text-left md:text-2xl text-xl font-bold mt-3">
                    How?
                  </h1>
                  <ul className="signuplist mt-3 text-left font-semibold text-neutral-600">
                    <li className="">
                      Get brutal honest feedback by IIM Grads to help you
                      prepare better .
                    </li>
                    <li className="">CV and Profile Building, WAT Prep.</li>
                    <li className="">
                      Transcript of previous year interviews of IIM grads.
                    </li>
                    <li className="">
                      100% Refund once you get an offer letter from IIMs.
                    </li>
                  </ul>
                  <div className="md:mt-8 mt-5 md:text-lg">
                    <p>
                      InterestSave, an IIM Alumni initiative, is more than just
                      a platform; we're your dedicated seniors on a mission to
                      empower ambitious MBA aspirants to secure a seat in top
                      Indian B-schools.{" "}
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-2.5/4 p-8 flex-1">
                <div className="block">
                  <h1 className="text-3xl font-bold mb-2">FREE Sign Up</h1>
                  <p className="text-gray-600 mb-4 md:text-base text-sm">
                    It costs you nothing.
                  </p>
                </div>
                <div className="grid grid-cols-2 gap-4 mt-8">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-600"
                    >
                      First Name
                      <p className="text-red-400">
                        {errors?.firstName?.message}
                      </p>
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      disabled={isLoading}
                      {...register("firstName", {
                        required: "First Name filed is required",
                      })}
                      className="border-b outline-none border-gray-300 w-full py-1"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-600"
                    >
                      Last Name
                      <p className="text-red-400">
                        {errors?.lastName?.message}
                      </p>
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      className="border-b outline-none border-gray-300 w-full py-1"
                      name="lastName"
                      disabled={isLoading}
                      {...register("lastName", {
                        required: "Last Name is required",
                      })}
                    />
                  </div>
                </div>

                <div className="mt-8">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-600"
                  >
                    Email
                    <p className="text-red-400">{errors?.email?.message}</p>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    disabled={isLoading}
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "entered email is not valid",
                      },
                    })}
                    className="border-b outline-none border-gray-300 w-full py-1"
                  />
                </div>

                <div className="grid grid-cols-2 gap-4 mt-8">
                  <div>
                    <label
                      htmlFor="phoneNumber"
                      className="block text-sm font-medium text-gray-600"
                    >
                      Phone Number
                      <p className="text-red-400">
                        {" "}
                        {errors?.phoneNumber?.message}
                      </p>
                    </label>
                    <input
                      type="tel"
                      id="phoneNumber"
                      name="phoneNumber"
                      disabled={isLoading}
                      {...register("phoneNumber", {
                        required: "Phone Number is required",
                        minLength: {
                          value: 10,
                          message: "Entered phone number is not valid",
                        },
                      })}
                      className="border-b outline-none border-gray-300 w-full py-1"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="referralCode"
                      className="block text-sm font-medium text-gray-600"
                    >
                      Referral Code
                      <p className="text-red-400">
                        {errors?.referralCode?.message}
                      </p>
                    </label>
                    <input
                      type="text"
                      id="referralCode"
                      name="referralCode"
                      placeholder="optional"
                      disabled={isLoading}
                      {...register("referralCode")}
                      className="border-b outline-none border-gray-300 w-full py-1"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4 mt-8 items-center ">
                  <div className="">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-600"
                    >
                      Password
                      <p className="text-red-400">
                        {errors?.password?.message}
                      </p>
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      disabled={isLoading}
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 6,
                          message: "Password needs a minimum of 6 characters",
                        },
                      })}
                      className="border-b outline-none border-gray-300 w-full py-1"
                    />
                  </div>
                  <div className="">
                    <label
                      htmlFor="confirmPassword"
                      className="block text-sm font-medium text-gray-600"
                    >
                      Confirm Password
                      <p className="text-red-400">
                        {errors?.confirmPassword?.message}
                      </p>
                    </label>
                    <input
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                      disabled={isLoading}
                      {...register("confirmPassword", {
                        required: "please enter the password",
                        validate: (value) =>
                          value === getValues().password ||
                          "Entered passwords donot match",
                      })}
                      className="border-b outline-none border-gray-300 w-full py-1"
                    />
                  </div>
                </div>

                <div className="mt-8 flex items-center">
                  <input
                    type="checkbox"
                    id="terms"
                    name="terms"
                    disabled={isLoading}
                    {...register("terms", {
                      required: "please accept terms and conditions",
                    })}
                    className="mr-2 checkmark"
                  />
                  <label
                    htmlFor="termsCheckbox"
                    className="text-sm text-gray-600"
                  >
                    By creating an account, you confirm that you agree to our{" "}
                    <Link to="/termsandcondition">
                      <span className="underline font-semibold cursor-pointer">
                        Terms of Use
                      </span>
                    </Link>{" "}
                    &{" "}
                    <Link to="/privacypolicy">
                      <span className="underline font-semibold cursor-pointer">
                        Privacy Policy.
                      </span>
                    </Link>
                    <p className="text-red-400"> {errors?.terms?.message}</p>
                  </label>
                </div>

                <button
                  disabled={isLoading}
                  className="bg-[#50A499] font-semibold text-white p-2 rounded w-full mt-4"
                >
                  {isLoading ? "Signing Up..." : "SIGN UP"}
                </button>
                <div className="flex items-center gap-2 mt-4">
                  <p className="">Already have an account? </p>
                  <NavLink to="/login">
                    <div className="text-[#50A499]">Login</div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
};

export default SignUp;
