import React, { useEffect, useState } from "react";
import { PiCheckCircle } from "react-icons/pi";
import Coins from "../assets/coins.png";
import Cash from "../assets/BuyIllustrations/wallet (1).png";
import { Link } from "react-router-dom";
import Price from "../components/Price";
import Image from "../utilities/Image";
import LoaderSpinner from "../components_/Loader";
import PaymentHandler from "../utilities/PaymentHandler";

const DashboardMain = () => {
  const [loading, setLoading] = useState(false);
  
  const handlePayment = (e, price, userdata) => {
    setLoading(true);
    PaymentHandler(e, price, userdata, setLoading);
  }
  useEffect(()=>
  {
    {!loading&&window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",})}
  }
  )
  return (
    <LoaderSpinner loading={loading}>
      <div className="lg:px-6 ">
        <div className="bg-[#F5F5F5] py-6 px-6 lg:px-12 rounded-xl">
          <div className="flex ">
            <div className="">
              <h1 className="text-3xl font-bold">
                <span className="text-[#22A699]">Interestsave’s</span> Exclusive
                Launch
              </h1>
              <p className="text-xl mt-2 font-semibold">
                100% <span className="text-[#22A699]">Refund</span>
              </p>
              <div className="mt-6">
                <div className="flex gap-2 lg:gap-5 mt-2 flex-col lg:flex-row">
                  <div className="">
                    <div className="flex gap-3 items-center">
                      <PiCheckCircle className="text-green-700 font-bold" />
                      <p className="flex-1">Online mock interview for free</p>
                    </div>
                    <div className="flex gap-3 mt-6 items-center">
                      <PiCheckCircle className="text-green-700 font-bold" />
                      <p className="flex-1">
                        1 on 1 mentorship with detailed feedback
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="flex gap-3 items-center">
                      <PiCheckCircle className="text-green-700 font-bold" />
                      <p className="flex-1">Get interviewed by IIM Alumni</p>
                    </div>
                    <div className="flex gap-3 mt-6 items-center">
                      <PiCheckCircle className="text-green-700 font-bold" />
                      <p className="flex-1">
                        Additional discount for interestsave members
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rounded-lg mt-8">
                <Link to='/buy'><button className="py-2 px-4 rounded-lg bg-black text-[#DBFFEF]">
                  How does 100% refund works?
                </button>
                </Link>
              </div>
            </div>
            <div className="hidden lg:block">
              <div className="max-w-sm mx-auto">
                <Image src={Coins} alt="coins" />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-8 flex xl:gap-8 gap-4 flex-col xl:flex-row ">
          <Price handlePayment={handlePayment} />
          <div className="w-full flex justify-center h-max xl:mt-[8rem]">
            <div className="bg-[#22A699] w-full rounded-xl bg-DashboardPattern bg-no-repeat bg-left-bottom ">
              <div className="flex py-6 lg:px-8 px-6 justify-between text-white">
                <div>
                  <h1 className="text-[#DBFFEF] text-3xl font-bold">MBA Loan</h1>
                  <p className="mt-2 max-w-sm text-lg break-words">
                    Your dream college is waiting for you. Let us help you in a
                    wonderfully path
                  </p>
                  <button className="py-2 rounded-lg font-semibold px-4 mt-6 bg-black text-[#fff]">
                    Apply for loan
                  </button>
                </div>
                <div className="hidden sm:flex justify-center items-center">
                  <div className="mx-auto max-w-[8rem] ">
                    <Image className="w-full h-full" src={Cash} alt="cashWallet" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoaderSpinner>
  );
};

export default DashboardMain;
