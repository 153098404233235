// import { createClient } from "@supabase/supabase-js";
// export const supabaseUrl = "https://zbxcmhnxwhgplmsyjhoa.supabase.co";
// const supabaseKey =
//   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InpieGNtaG54d2hncGxtc3lqaG9hIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTk0NzEzMTksImV4cCI6MjAxNTA0NzMxOX0.XiYbgXhdj2ezJd5nFw3GPy0-fBDL4j3LfEKuFwB5TrU";
// export const supabase = createClient(supabaseUrl, supabaseKey);

import { createClient } from "@supabase/supabase-js";
export const supabaseUrl = "https://stomcnibgnqyffgvnadu.supabase.co";
const supabaseKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InN0b21jbmliZ25xeWZmZ3ZuYWR1Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDA1NTk3MTksImV4cCI6MjAxNjEzNTcxOX0.F9b_Fmspm8_QOlY1qkfPNBU2wl17R1SzVO3Px6_KHE0";
export const supabase = createClient(supabaseUrl, supabaseKey);
