import React from "react";
// import Hero from "../assets/hero/heroDesktop.png";
import { NavLink } from "react-router-dom";
import Image from "../utilities/Image";

const Header = ({ hero, isLoading }) => {
  return (
    <section className="max-w-7xl w-[90%] flex flex-col lg:flex-row mx-auto">
      <div className="w-full lg:w-1/2 mx-auto">
        <div className="max-w-xl text-center lg:text-left lg:mx-0 mx-auto flex flex-col items-center lg:items-start h-full lg:pt-20">
          <p className="text-3xl lg:text-4xl lg:leading-[2.8rem] lg:mr-14 font-bold">
          Let's work together to crack your dream B-school and get best Interest rate on{" "}
            <span className="text-[#22A699] ">MBA Loan.</span>
          </p>
          <div className="max-w-xl lg:hidden flex items-center ">
            <Image
              src="https://stomcnibgnqyffgvnadu.supabase.co/storage/v1/object/public/homepage/hero/heromain_mobile.jpg"
              alt="avatars"
            />
          </div>
          <p className="text-lg lg:text-xl mt-6 font-regular lg:mr-14">
            We compel lenders to compete, offering you rates below what you'd
            get alone.
          </p>
          <div className="flex-1">
          <NavLink to="/dashboard/main">
            <button className="lg:hidden block py-4 text-xl px-12 mt-5 duration-150 hover:shadow-lg hover:shadow-neutral-300 rounded-full text-[#DBFFEF] font-bold bg-[#22A699]/90 hover:bg-[#37978e]">
              Join Us-it's free
            </button>
          </NavLink>
          </div>
          <NavLink to="/dashboard/main">
            <button className="hidden lg:block py-4 text-xl px-12 mt-5 duration-150 hover:shadow-lg hover:shadow-neutral-300 rounded-full text-[#DBFFEF] font-bold bg-[#22A699]/90 hover:bg-[#37978e]">
              Join Us-it's free
            </button>
            <p className="text-center italic mt-1 text-sm font-medium">An IIM Alumni Initiative</p>
          </NavLink>
        </div>
      </div>
      <div className="max-w-xl hidden lg:flex items-center">
        <Image
          src="https://stomcnibgnqyffgvnadu.supabase.co/storage/v1/object/public/homepage/hero/heromain_desktop.jpg"
          alt="avatars"
        />
      </div>
    </section>
  );
};

export default Header;
