import React from 'react'

const YoutubeVideo = () => {
    return (
        <div className='max-w-7xl mx-auto w-[95%] mt-12'>
            <div className=''>
                <iframe loading='lazy' className='mx-auto md:w-[40rem] w-full h-72 sm:h-96 ' src="https://www.youtube.com/embed/V0y0HE-4nbo" title="Vartika&#39;s Journey to IIM after a failed attempt | The IIM Journey | Ep 5" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
    )
}

export default YoutubeVideo
