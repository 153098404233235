import React, { useEffect } from 'react';

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="min-h-screen flex items-center md:py-12 py-4 justify-center">
      <div className="bg-white p-8 md:rounded-lg md:shadow-lg  w-full md:w-2/3 lg:w-1/2">
        <h2 className="text-4xl font-extrabold mb-6 text-gray-800 text-center">Contact Us</h2>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2 text-gray-700">General Enquiries:</h3>
          <p className="text-gray-500">Feel free to reach us at <a href="mailto:team@interestsave.com" className="text-[#22A699]">team@interestsave.com</a> for any business or general enquiry.</p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2 text-gray-700">Customer Support:</h3>
          <p className="text-gray-500">Our dedicated customer support team is here to assist you. If you have any questions or issues, please don't hesitate to contact us at <a href="mailto:support@interestsave.com" className="text-[#22A699]">support@interestsave.com</a>.</p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2 text-gray-700">Business Hours:</h3>
          <p className="text-gray-500">Monday - Saturday: 9:00 AM - 6:00 PM (GMT)</p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2 text-gray-700">Mobile No:</h3>
          <p className="text-gray-500">+91-9089198810</p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2 text-gray-700">Office Address:</h3>
          <p className="text-gray-500">
            Opposite SBI Bank, Bankar,<br />
            Khowai, 799201,<br />
            Tripura
          </p>
        </div>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2 text-gray-700">Social Media:</h3>
          <p className="text-gray-800">
            <a target='_blank' href="https://www.linkedin.com/company/interestsave/" className="text-[#22A699] mr-3 hover:underline">LinkedIn</a>
            <a target='_blank' href="https://www.youtube.com/@InterestSave" className="text-[#22A699] mr-3 hover:underline">YouTube</a>
            <a target='_blank' href="https://instagram.com/interestsave" className="text-[#22A699] mr-3 hover:underline">Instagram</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
