import React from "react";
import { IoMicOffOutline } from "react-icons/io5";
const ServiceCard = ({
  servicename,
  servicedescription,
  icon: Icon,
  glassmorphism = false,
}) => {
  return (
    <div
      className={`py-6 px-4 flex-1 ${
        glassmorphism &&
        "bg-gradient-to-br rounded-2xl from-gray-100/10 to-white/10 backdrop-filter backdrop-blur-md bg-opacity-10 shadow-lg"
      }`}
    >
      <div>
        <div className="w-full">
          <div className="w-24 h-24 bg-white shadow-lg rounded-full mx-auto flex justify-center items-center">
            <Icon className="text-5xl" />
          </div>
        </div>
        <div className="text-center mt-4">
          <h1 className={`text-2xl ${glassmorphism?'font-bold':'font-semibold'}`}>{servicename}</h1>
          <p className="font-medium mt-2">
            {servicedescription}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
