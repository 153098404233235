import { React, useContext } from "react";
import { RiCloseLine } from "react-icons/ri";
import { MdHome } from "react-icons/md";
import { CiMoneyBill } from "react-icons/ci";
import { Link, useNavigate } from "react-router-dom";
import { CiMicrophoneOn } from "react-icons/ci";
import { BsQuestionLg } from "react-icons/bs";
import { FaUserGroup } from "react-icons/fa6";
import { refContext } from "../store/ContextProvider";
import Logout from "../components_/Logout";
import ProfileCard from "./ProfileCard";
import { GiDiscussion } from "react-icons/gi";

const Drawer = ({
  handleMenu,
  menuOpen,
  isAuthenticated,
  isLoading,
  generateProfile,
}) => {
  const refCtx = useContext(refContext);
  const navigate = useNavigate();

  return (
    <div
      className={`h-screen z-40 fixed top-0 right-0 bg-white duration-300 w-72 transition-transform transform ease-in-out ${
        menuOpen ? "translate-x-0 " : "translate-x-full"
      } px-3 py-4`}
    >
      <div className="h-full flex flex-col">
        <div className=" flex justify-end items-center">
          {/* <ProfileCard /> */}
          <RiCloseLine onClick={handleMenu} className="text-4xl sm:text-4xl" />
        </div>
        <div className="">
          <ul className="mt-6 text-xl font-medium flex flex-col gap-3">
            <li
              onClick={() => {
                {
                  navigate("/");
                  window.scrollTo(0, 0);
                }
              }}
              className="flex items-center gap-2 px-4 py-3 rounded-xl duration-150 ease-in-out hover:bg-[#22A699]/20 hover:text-[#000]"
            >
              <MdHome className="text-xl" />
              Home
            </li>
            <li
              onClick={() => refCtx.scrollToView(refCtx.benefitRef, navigate)}
              className="flex items-center gap-2 px-4 py-3 rounded-xl duration-150 ease-in-out hover:bg-[#22A699]/20 hover:text-[#000]"
            >
              <CiMoneyBill className="text-xl" />
              Benefits
            </li>
            <Link onClick={handleMenu} to="/buy"><li
              className="flex items-center gap-2 px-4 py-3 rounded-xl duration-150 ease-in-out hover:bg-[#22A699]/20 hover:text-[#000]"
            >
              <GiDiscussion className="text-xl" />
              GDPI
            </li></Link>
            <Link target="_blank" to="https://www.youtube.com/@InterestSave/videos"><li
              className="flex items-center gap-2 px-4 py-3 rounded-xl duration-150 ease-in-out hover:bg-[#22A699]/20 hover:text-[#000]"
            >
              <CiMicrophoneOn className="text-xl" />
              Podcasts
            </li></Link>
            <li
              onClick={() => refCtx.scrollToView(refCtx.faqsRef, navigate)}
              className="flex items-center gap-2 px-4 py-3 rounded-xl duration-150 ease-in-out hover:bg-[#22A699]/20 hover:text-[#000]"
            >
              <BsQuestionLg className="text-xl" />
              FAQs
            </li>
            <li
              onClick={() => refCtx.scrollToView(refCtx.aboutusRef, navigate)}
              className="flex items-center gap-2 px-4 py-3 rounded-xl duration-150 ease-in-out hover:bg-[#22A699]/20 hover:text-[#000]"
            >
              <FaUserGroup className="text-xl" />
              About Us
            </li>
          </ul>
        </div>
        <div className="flex flex-col justify-end flex-1">
          {isAuthenticated && !isLoading ? (
            <Link onClick={handleMenu} to="/dashboard/main">
              <button className="rounded-full w-full py-3 font-medium text-lg px-4 bg-[#22A699] text-white">
                Dashboard
              </button>
              <Logout />
            </Link>
          ) : (
            <>
              <Link onClick={handleMenu} to="/login">
                <button className="rounded-full w-full py-3 font-medium text-lg px-4 bg-[#22A699] text-white">
                  Login
                </button>
              </Link>
              <div className="flex justify-center items-center gap-2">
                <hr className="flex-1 border-neutral-400" />
                <p className="text-lg">or</p>
                <hr className="flex-1 border-neutral-400" />
              </div>
              <Link onClick={handleMenu} to="/signup">
                <button className="rounded-full w-full py-3 font-medium text-lg px-4 bg-[#22A699] text-white">
                  Create an account
                </button>
              </Link>
            </>
          )}{" "}
        </div>
      </div>
    </div>
  );
};

export default Drawer;
