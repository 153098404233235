import logo from "./logo.svg";
import "./App.css";
import Home from "./pages/Home";
import Layout from "./layouts/Layout";
import {
  BrowserRouter,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
} from "react-router-dom";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";

// new additions();

import ProtectRoutes from "./components_/ProtectRoutes";
//react query
import { QueryClient } from "@tanstack/react-query";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
//notifications
import { Toaster } from "react-hot-toast";
//pages
import Profile from "./pages/Profile";
import Settings from "./pages/Settings";
import ForgotPassword from "./pages/ForgotPassword";
import NotFound from "./pages/NotFound";
import Dashboard from "./pages/Dashboard";
import DashboardMain from "./pages/DashboardMain";
import Buy from "./components/Buy/Buy";
import Pay from "./components/Pay";
import Policy from "./components/Policies/Policy";
import PaymentSuccess from "./pages/Payment/PaymentSuccess";
import ContactUs from "./components/ContactUs";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * 1000,
    },
  },
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <BrowserRouter>
        <Routes>
          {/* Root Route */}
          <Route element={<Layout />}>
            {/* Dashboard is the child route of root route */}
            <Route element={<Buy />} path="/buy" />
            {/* <Route
              path="buy"
              element={
                <ProtectRoutes>
                  <Buy />
                </ProtectRoutes>
              }
            /> */}
            <Route element={<Pay />} path="/pay" />
            <Route element={<PaymentSuccess />} path="/success" />
            <Route
              // path="dashboard"
              element={
                <ProtectRoutes>
                  <Dashboard />
                </ProtectRoutes>
              }
            >
              <Route
                path="dashboard/main"
                index
                element={
                  <ProtectRoutes>
                    <DashboardMain />
                  </ProtectRoutes>
                }
              />
              {/* Child route of dashboard route */}
              <Route
                path="dashboard/settings"
                element={
                  <ProtectRoutes>
                    <Settings />
                  </ProtectRoutes>
                }
              />
              {/* Child route of dashboard route */}
              <Route
                path="dashboard/profile"
                element={
                  <ProtectRoutes>
                    <Profile />
                  </ProtectRoutes>
                }
              />
            </Route>
            <Route index element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="*" element={<NotFound />} />
            <Route element={<Policy />} path="/privacypolicy" />
            <Route element={<Policy />} path="/termsandcondition" />
            <Route element={<Policy />} path="/refundpolicy" />
            <Route element={<Policy />} path="/cancellationpolicy" />
          </Route>
        </Routes>
      </BrowserRouter>
      <Toaster
        position="top-center"
        gutter={12}
        containerStyle={{ margin: "8px" }}
        toastOptions={{
          success: {
            duration: 3000,
          },
          error: {
            duration: 3000,
          },
          style: {
            fontsize: "14px",
            maxWidth: "300px",
            padding: "12px 12px",
            color: "#800080",
          },
        }}
      />
    </QueryClientProvider>
  );
}

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Layout />,
//     children: [
//       {
//         path: "/",
//         element: <Home />,
//       },
//       {
//         path: "/login",
//         element: <Login />,
//       },
//       {
//         path: "/signup",
//         element: <SignUp />,
//       },
//     ],
//   },
// ]);

// function App() {
//   return (
//     <RouterProvider router={router}>
//       <div className="App"></div>
//     </RouterProvider>
//   );
// }

// export default App;
