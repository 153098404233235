import React, { useState } from 'react'
import ProfileImage from '../components_/ProfileImage'
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { NavLink } from 'react-router-dom';
import Logout from '../components_/Logout';

const ProfileCard = ({expanded, handleProfileClick}) => {

  return (
    <div className='relative z-10 cursor-pointer '>
      <section onClick={handleProfileClick} className='flex relative gap-2  items-center'>
        <div>
          <div className='w-10 rounded-full'>
            <ProfileImage style="rounded-full" />
          </div>
        </div>
        <div className='cursor-pointer text-white'>
          {expanded? <RiArrowDropUpLine className='text-2xl' /> :<RiArrowDropDownLine className='text-2xl' />}
        </div>
      </section>
      <div className={`duration-200 py-4 px-4 ${expanded?'h-max-full block':'hidden'} bg-white absolute top-12 -left-8 shadow-md`}>
        <div>
          <ul className='flex flex-col gap-3'>
            <NavLink to='/dashboard/main'><li onClick={handleProfileClick} className='py-2 px-4 rounded-lg duration-200 ease-in-out hover:bg-neutral-100'>Dashboard</li></NavLink>
            <NavLink to='/dashboard/profile'><li onClick={handleProfileClick} className='py-2 px-4 rounded-lg duration-200 ease-in-out hover:bg-neutral-100'>Edit Profile</li></NavLink>
            <NavLink to='/dashboard/settings'><li onClick={handleProfileClick} className='py-2 px-4 rounded-lg duration-200 ease-in-out hover:bg-neutral-100'>Settings</li></NavLink>
            <li onClick={handleProfileClick} className='py-2 cursor-pointer px-4 rounded-lg duration-200 ease-in-out hover:bg-neutral-100'><Logout style="text-left w-full">Logout</Logout></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ProfileCard
