import React, { useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import { FaAngleUp } from "react-icons/fa6";

const Faqs = [
    {
        id: 1,
        serial: '01',
        question: "Does InterestSave offer financial assistance?",
        answer:"InterestSave operates uniquely as an educational financing enabler rather than a direct financial institution in the lending landscape. Our role revolves around empowering the pursuit of education. We've forged impactful collaborations with prominent financial entities all over India.",
    },{
        id: 2,
        serial: '02',
        question: "When would be the right time to apply for a loan through InterestSave?",
        answer:"It's a great idea to begin evaluating your financial options well ahead of your planned study period. A smart initial step is to explore scholarships through our InterestSave platform. If you happen to not discover the ideal scholarship, don't hesitate to initiate a loan application promptly. This way, you can tap into a broader pool of potential Lenders. ",
    },
    {
        id: 3,
        serial: '03',
        question: "How does InterestSave safeguard my personal information and data privacy?",
        answer:"As consumers enjoying a variety of services, few things frustrate us as much as our personal data being disclosed without our consent. At InterestSave, safeguarding your information is our priority. We pledge not to share your data with external parties for solicitation, unless it's essential to fulfill your specific requests. For a deeper understanding, take a glance at our comprehensive privacy document. What's more, at InterestSave, we employ cutting-edge encryption techniques, assuring that the information you entrust us with remains cocooned in complete security.",
    },{
        id: 4,
        serial: '04',
        question: "How InterestSave make money?",
        answer:"InterestSave collaborates with leading financial institutions to offer loans, and as part of our partnership agreements, we apply an origination fee as a percentage of the loan amount. It's important to highlight that we do not impose any charges on students for the loans we facilitate through our banking partners.",
    },{
        id: 5,
        serial: '05',
        question: "I want to know more. How and whom should I contact? ",
        answer:"We're excited to connect with you to help address your questions and are eagerly awaiting any insights or ideas you might have about our product lineup. Feel free to drop us a line at team@interestsave.com – we're all ears!",
    },
]

const FAQ = ({refCtx}) => {
    const [current, setCurrent] = useState(null);
    const toggleHandler = (i) =>{
        if(i===current) return setCurrent(null);
        setCurrent(i);
        console.log(current);
    }
  return (
    <div ref={refCtx.faqsRef} className="mt-20">
      <section className="max-w-7xl mx-auto w-[90%]">
        <div className="text-center md:text-left md:w-96"><h1 className="text-4xl font-bold">Frequently Asked <span className="text-[#22A699]">Questions</span></h1></div>
        <div className="mt-10 ">
            <div className="max-w-7xl mx-auto flex gap-3 flex-col">
                {Faqs.map((faq,i)=>{
                    return(
                        <div key={faq.id}  className={`py-4 px-6 bg-neutral-100 rounded-xl `}>
                            <div onClick={()=>toggleHandler(i)} className="flex items-center cursor-pointer  justify-between gap-6">
                                <span className="text-2xl md:text-4xl  text-[#22A699] font-semibold">{faq.serial}</span>
                                <div className="flex-1"> 
                                    <h1 className="text-lg md:text-xl font-semibold">{faq.question}</h1>
                                </div>
                                {current===i?<FaAngleUp className="text-xl"/> :<FaAngleDown className="text-xl" />}
                            </div>
                            <div className={`mt-4 ${current!==i? 'max-h-0 overflow-hidden hidden duration-300 ease-in-out transition-all':'h-auto max-h-max block duration-300 ease-in-out transition-all'}`}>{faq.answer} </div>
                        </div>
                    )
                })}
            </div>
        </div>
      </section>
    </div>
  );
};

export default FAQ;
