  // React-slick setting for carousel

export const Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide:0,
    appendDots: (dots) => (
        <div
            style={{
            height: "14px",
            }}
        >
            <ul style={{ marginTop: "16px" }}> {dots} </ul>
        </div>
        ),
        responsive: [
        {
            breakpoint: 1024,
            settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
            }
        },
        {
            breakpoint: 515,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1
            }
        }
        ],
    };