import React from "react";
import ServiceCard from "../ui/ServiceCard";
import { MdOutlineGroups2 } from "react-icons/md";
import { FaHandsHelping } from "react-icons/fa";
import { RiGroup2Line } from "react-icons/ri";

const Services = () => {
  return (
    <div className="mt-12 md:mt-20 bg-gradient-to-b from-[#ffffff] to-[#bbefe9] pb-12 ">
      <div className="w-full ">
        <h1 className="text-3xl md:text-4xl font-bold text-center">
          Our <span className="text-[#22A699]">Services</span>
        </h1>
      </div>
      <section className="max-w-6xl mt-8 mx-auto flex gap-4 md:gap-6 flex-col md:flex-row w-[90%] justify-center">
        <ServiceCard
          servicename="Mock Interview"
          servicedescription="Get brutal feedback on your mock GDPI. 1-1 personalized mentorship from IIM Alumni and get help and suggestion in Profile Building."
          icon={RiGroup2Line}
        />
        <ServiceCard
          servicename="Loan"
          servicedescription="We assist students in getting the best Interest Rate on their MBA loan from Banks for FREE. "
          icon={FaHandsHelping}
          glassmorphism={true}
        />
        <ServiceCard
          servicename="Mock GD"
          servicedescription="Build confidence and sharpen GD skills with a practice session alongside your future batchmates. Let's do this together."
          icon={MdOutlineGroups2}
        />
      </section>
    </div>
  );
};

export default Services;
