import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div>
      <section class="mb-8">
        <h1 class="text-2xl font-bold mb-4">Introduction</h1>
        <p>
          Welcome to InterestSave! This Privacy Policy outlines how we collect,
          use, and protect your personal information. By using our services, you
          consent to the terms outlined in this policy.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Information Collection</h2>
        <ol class="list-decimal ml-6">
          <li>
            Personal Information: We may collect personal information such as
            your name, contact details, and educational background when you
            register on our platform.
          </li>
          <li>
            Usage Data: We gather information on how you interact with our
            website, including pages visited, time spent, and other analytics to
            enhance user experience.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Use of Information</h2>
        <ol class="list-decimal ml-6">
          <li>
            Service Improvement: We use collected data to enhance and
            personalize your experience, tailoring our services to meet your
            needs.
          </li>
          <li>
            Communication: Your information may be used to communicate important
            updates, promotions, and relevant content.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Data Security</h2>
        <ol class="list-decimal ml-6">
          <li>
            Confidentiality: We prioritize the security of your personal
            information and employ industry-standard measures to protect against
            unauthorized access.
          </li>
          <li>
            Third-Party Services: We may use third-party services to process
            data securely. Their use is governed by their respective privacy
            policies.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Cookies and Tracking</h2>
        <ol class="list-decimal ml-6">
          <li>
            Cookies: We use cookies to improve user experience, track website
            usage patterns, and gather information for analytics.
          </li>
          <li>
            Opt-out: You can manage cookie preferences through your browser
            settings.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Disclosure of Information</h2>
        <ol class="list-decimal ml-6">
          <li>
            Third-Party Partners: We may share your information with trusted
            third-party partners for specific services. However, we ensure they
            adhere to strict privacy standards.
          </li>
          <li>
            Legal Compliance: Information may be disclosed in response to legal
            requests or to comply with applicable laws and regulations.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">User Rights</h2>
        <ol class="list-decimal ml-6">
          <li>
            Access and Correction: You have the right to access and correct your
            personal information.
          </li>
          <li>
            Withdrawal of Consent: You can withdraw your consent for specific
            data processing activities at any time.
          </li>
        </ol>
      </section>
    </div>
  );
};

const RefundPolicy = () => {
  return (
    <div>
      <section class="mb-8">
        <h1 class="text-2xl font-bold mb-4">Introduction</h1>
        <p>
          This Refund Policy outlines the guidelines for requesting and
          processing refunds for services provided by InterestSave. By using our
          services, you agree to comply with the terms outlined in this policy.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Refund Eligibility</h2>
        <ol class="list-decimal ml-6">
          <li>
            Service Non-Delivery: If the purchased service is not delivered
            within the agreed-upon timeframe, you may be eligible for a refund.
          </li>
          <li>
            Service Deficiency: Refunds may be considered if the delivered
            service does not meet the specified standards or if there is a
            significant deficiency.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Refund Process</h2>
        <ol class="list-decimal ml-6">
          <li>
            Initiating Refund Requests: To initiate a refund, users must contact
            our customer support team at{" "}
            <a href="mailto:team@interestsave.com" class="text-blue-500">
              team@interestsave.com
            </a>{" "}
            within 3 weeks from purchase.
          </li>
          <li>
            Providing Details: Users must provide relevant details, including
            the reason for the refund request, transaction ID, and any
            supporting documentation.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Refund Criteria</h2>
        <ol class="list-decimal ml-6">
          <li>
            Service Non-Delivery: If the service is not delivered within the
            agreed-upon timeframe, a full refund may be processed.
          </li>
          <li>
            Service Deficiency: Refunds for deficiencies will be considered on a
            case-by-case basis, and a partial or full refund may be issued
            depending on the nature and severity of the deficiency.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Refund Limitations</h2>
        <ol class="list-decimal ml-6">
          <li>
            Service Utilization: Refunds will not be processed if the user has
            utilized a significant portion of the service.
          </li>
          <li>
            User Violations: Refunds will not be granted if the user has
            violated our Terms & Conditions.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Refund Processing</h2>
        <ol class="list-decimal ml-6">
          <li>
            Evaluation: InterestSave will evaluate refund requests within 3
            weeks from the date of submission of request.
          </li>
          <li>
            Notification: Users will be notified of the refund decision via
            email.
          </li>
          <li>
            Processing Time: Refunds, if approved, will be processed within 3
            weeks via the original payment method.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Refund Contact</h2>
        <p>
          For refund inquiries or to initiate a refund request, please contact
          our customer support team at{" "}
          <a href="mailto:team@interestsave.com" class="text-blue-500">
            team@interestsave.com
          </a>
          .
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Policy Changes</h2>
        <p>
          InterestSave reserves the right to modify this Refund Policy at any
          time. Users will be notified of significant changes via email or
          through the platform.
        </p>
      </section>

      <section class="mb-8">
        <p class="text-sm text-gray-600">
          By using our services, you acknowledge that you have read, understood,
          and agree to abide by the terms outlined in this Refund Policy.
        </p>
      </section>
    </div>
  );
};

const CancellationPolicy = () => {
  return (
    <div>
      <section class="mb-8">
        <h1 class="text-2xl font-bold mb-4">Introduction</h1>
        <p>
          This Cancellation Policy outlines the guidelines and procedures
          regarding the cancellation of services provided by InterestSave. We
          appreciate your trust in our platform, and by using our services, you
          agree to adhere to the terms set forth in this policy.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Cancellation Requests</h2>
        <ol class="list-decimal ml-6">
          <li>
            Users may request a cancellation by contacting our customer support
            team at{" "}
            <a href="mailto:team@interestsave.com" class="text-blue-500">
              team@interestsave.com
            </a>{" "}
            within 3 weeks from the date of purchase.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Eligibility for Cancellation</h2>
        <ol class="list-decimal ml-6">
          <li>
            Cancellations will be considered for undelivered services or
            instances where the service does not meet specified standards.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Cancellation Process</h2>
        <ol class="list-decimal ml-6">
          <li>
            Users must provide necessary details, including the reason for
            cancellation, transaction ID, and any supporting documentation.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Cancellation Limitations</h2>
        <ol class="list-decimal ml-6">
          <li>
            No cancellations will be processed if a significant portion of the
            service has been utilized.
          </li>
          <li>
            Cancellations will not be granted if the user has violated our Terms
            & Conditions.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Cancellation Notification:</h2>
        <ol class="list-decimal ml-6">
          <li>
            Users will be notified of the cancellation decision via email within
            3 weeks from the date of the cancellation request.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Cancellation Contact</h2>
        <p>
          For cancellation inquiries or to initiate a cancellation request,
          please contact our customer support team at{" "}
          <a href="mailto:team@interestsave.com" class="text-blue-500">
            team@interestsave.com
          </a>
          .
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Policy Changes</h2>
        <p>
          InterestSave reserves the right to modify this Refund Policy at any
          time. Users will be notified of significant changes via email or
          through the platform.
        </p>
      </section>

      <section class="mb-8">
        <p class="text-sm text-gray-600">
          By using our services, you acknowledge that you have read, understood,
          and agree to abide by the terms outlined in this Refund Policy.
        </p>
      </section>
    </div>
  );
};

const TermAndCondition = () => {
  return (
    <div>
      <section class="mb-8">
        <h1 class="text-2xl font-bold mb-4">Acceptance of Terms</h1>
        <ol class="list-decimal ml-6">
          <li>
            Agreement: By using our services, you agree to abide by these Terms
            and Conditions.
          </li>
          <li>
            Eligibility: Users must be at least 18 years old and possess legal
            authority to enter into this agreement.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">User Obligations</h2>
        <ol class="list-decimal ml-6">
          <li>
            Account Responsibility: Users are responsible for maintaining the
            confidentiality of their account information.
          </li>
          <li>
            Lawful Use: Users agree to use our services for lawful purposes and
            not engage in disruptive conduct.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Intellectual Property</h2>
        <ol class="list-decimal ml-6">
          <li>
            Ownership: All content on our platform is the property of
            InterestSave and is protected by intellectual property laws.
          </li>
          <li>
            Prohibited Use: Users may not reproduce, distribute, or modify
            content without explicit consent.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Liability</h2>
        <ol class="list-decimal ml-6">
          <li>
            Limitation: InterestSave is not liable for direct, indirect, or
            consequential damages arising from service use.
          </li>
          <li>
            Indemnity: Users agree to indemnify InterestSave against any claims
            arising from their use of our services.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Modifications</h2>
        <ol class="list-decimal ml-6">
          <li>
            Updates: InterestSave reserves the right to modify these terms and
            conditions. Continued use after changes constitutes acceptance.
          </li>
          <li>
            Notification: Users will be notified of significant changes via
            email or through the platform.
          </li>
        </ol>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Governing Law</h2>
        <p class="ml-6">
          Jurisdiction: These terms are governed by and construed in accordance
          with the laws of India.
        </p>
      </section>

      <section class="mb-8">
        <h2 class="text-xl font-bold mb-4">Contact Information</h2>
        <p class="ml-6">
          For questions or concerns regarding our Privacy Policy and Terms &
          Conditions, contact us at{" "}
          <a href="mailto:team@interestsave.com" class="text-blue-500">
            team@interestsave.com
          </a>
        </p>
      </section>

      <section>
        <p>
          These comprehensive Privacy Policy and Terms & Conditions ensure
          transparency and adherence to legal standards, promoting a secure and
          trustworthy user experience on InterestSave.
        </p>
      </section>
    </div>
  );
};

const Policy = () => {
  const currentPath = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPath]);
  return (
    <div className="pb-8">
      <div className="md:py-24 py-16 flex justify-center bg-gray-200">
        <h1 className="text-3xl font-medium">
          {currentPath.pathname === "/privacypolicy" && "Privacy Policy"}
          {currentPath.pathname === "/refundpolicy" && "Refund Policy"}
          {currentPath.pathname === "/cancellationpolicy" &&
            "Cancellation Policy"}
          {currentPath.pathname === "/termsandcondition" &&
            "Terms and Condition"}
        </h1>
      </div>
      <div>
        <div className="max-w-6xl w-[90%] mx-auto mt-6">
          {currentPath.pathname === "/privacypolicy" && <PrivacyPolicy />}
          {currentPath.pathname === "/refundpolicy" && <RefundPolicy />}
          {currentPath.pathname === "/cancellationpolicy" && (
            <CancellationPolicy />
          )}
          {currentPath.pathname === "/termsandcondition" && (
            <TermAndCondition />
          )}
        </div>
      </div>
    </div>
  );
};

export default Policy;
