import { useNavigate } from "react-router-dom";
import useUser from "../hook/useUser";
import { useEffect } from "react";

export default function ProtectRoutes({ children }) {
  const navigate = useNavigate();
  //first load the authenticated user
  const { data, isLoading, isAuthenticated } = useUser();
  console.log("protect page", data, isLoading, isAuthenticated);

  //if user is not authenticated, redirect page to homepage
  useEffect(
    function () {
      if (!isAuthenticated && !isLoading) {
        navigate("/login");
      }
    },
    [isLoading, isAuthenticated, navigate]
  );

  //while loading, show a loader
  //   if (isLoading) return {<Loader></Loader>}
  //if user is authenticated already, render the component/page on the url

  if (isAuthenticated) return children;
}
