import useUser from "../hook/useUser";
import { supabase } from "../api/supabase";
import { getProfileData } from "../api/CreateProfile";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import useProfileEdit from "../hook/useProfileEdit";
import LoaderSpinner from "./Loader";
import "../index.css";
import ProfileImage from "./ProfileImage";

export default function ProfileEditForm({ preloadedValues, userId }) {
  const { profileEdit, isLoading } = useProfileEdit();
  const { register, formState, getValues, reset, handleSubmit } = useForm({
    defaultValues: preloadedValues,
  });

  const { errors } = formState;

  console.log("preloaded values", preloadedValues);

  //submit edited form
  function formSubmit({
    firstname,
    lastname,
    email,
    phonenumber,
    referralcode,
    image,
  }) {
    const data = {
      id: userId,
      firstname: firstname,
      lastname: lastname,
      email: email,
      phonenumber: phonenumber,
      referralcode: referralcode,
      image: image,
    };
    profileEdit(data);
  }

  return (
    <div className="mt-4">
      <form onSubmit={handleSubmit(formSubmit)}>
        <div className="form-group flex flex-col md:flex-row md:gap-2 gap-4 md:items-center mt-4 ">
          <div className="flex gap-2 flex-col md:flex-row md:items-center">
            <label className="text-xl font-medium" htmlFor="firstname">First Name</label>
            {errors?.firstname?.message}
            <input
            className="py-2 px-3 rounded-lg border border-neutral-300 outline-none"
              type="text"
              id="firstname"
              name="firstname"
              disabled={isLoading}
              {...register("firstname", {
                required: "First Name filed is required",
              })}
            />
          </div>
          <div className="flex gap-2 flex-col md:flex-row md:items-center">
            <label className="text-xl font-medium" htmlFor="lastname">Last Name</label>
            {errors?.lastname?.message}
            <input
            className="py-2 px-3 rounded-lg border border-neutral-300 outline-none"
              type="text"
              id="lastname"
              name="lastname"
              disabled={isLoading}
              {...register("lastname", {
                required: "Last Name is required",
              })}
            />
          </div>
        </div>

        <div className="form-group mt-4 flex flex-col md:flex-row md:items-center gap-2">
          <label className="text-xl font-medium" htmlFor="email">Email</label>
          {errors?.email?.message}
          <input
          className="py-2 px-2 rounded-lg border-none outline-none"
            disabled={true}
            type="email"
            id="email"
            name="email"
            {...register("email", {
              required: "Email is required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "entered email is not valid",
              },
            })}
          />
        </div>

        <div className="form-group flex flex-col md:flex-row md:gap-2 gap-4 md:items-center mt-4 ">
          <div className="flex flex-col md:flex-row md:items-center gap-2">
            <label className="text-xl font-medium" htmlFor="phonenumber">Phone Number</label>
            {errors?.phonenumber?.message}
            <input
              className="py-2 px-2 rounded-lg border border-neutral-300 outline-none"
              type="tel"
              id="phonenumber"
              name="phonenumber"
              disabled={isLoading}
              {...register("phonenumber", {
                required: "Phone Number is required",
                minLength: {
                  value: 10,
                  message: "Entered phone number is not valid",
                },
              })}
            />
          </div>
          <div className="flex flex-col md:flex-row md:items-center gap-2">
            <label className="text-xl font-medium" htmlFor="referrlcode">Referral Code</label>
            {errors?.referralcode?.message}
            <input
              className="py-2 px-2 rounded-lg border border-neutral-300 outline-none"
              type="text"
              id="referralcode"
              name="referralcode"
              disabled={isLoading}
              placeholder="optional"
              {...register("referralcode")}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:items-center gap-2 mt-4">
          <label className="text-xl font-medium">Profile Picture</label>
          {errors?.image?.message}
          <input
            type="file"
            accept="image/*"
            id="image"
            name="image"
            {...register("image", {
              validate: (value) => {
                if (value[0]) {
                  const file = value[0];
                  //check file size
                  if (file?.size > 200 * 1024) {
                    return "File size should be less than 300kb";
                  }
                  //check file type
                  const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
                  if (!allowedTypes.includes(file.type)) {
                    return "Invalid file type. Please upload an image (JPEG, PNG, GIF)";
                  }
                }
                return true;
              },
            })}
          />
          <div className="w-12 h-12 rounded-full">
            <ProfileImage style='rounded-full' />
          </div>
        </div>
        <button className="mt-4 py-2 px-4 bg-[#22A699] text-white rounded-lg">{isLoading ? "updating.." : "Update profile"}</button>
      </form>
    </div>
  );

  // const { updateUser, isUpdating } = useUpdateUser();

  // const [fullName, setFullName] = useState(currentFullName);
  // const [avatar, setAvatar] = useState(null);

  // function handleSubmit(e) {
  //   e.preventDefault();
  //   if (!fullName) return;
  //   updateUser(
  //     { fullName, avatar },
  //     {
  //       onSuccess: () => {
  //         setAvatar(null);
  //         e.target.reset();
  //       },
  //     }
  //   );
  // }

  // function handleCancel() {
  //   setFullName(currentFullName);
  //   setAvatar(null);
  // }

  // return (
  //   <Form onSubmit={handleSubmit}>
  //     <FormRow label="Email address">
  //       <Input value={email} disabled />
  //     </FormRow>

  //     <FormRow label="Full name">
  //       <Input
  //         type="text"
  //         value={fullName}
  //         onChange={(e) => setFullName(e.target.value)}
  //         id="fullName"
  //         disabled={isUpdating}
  //       />
  //     </FormRow>

  //     <FormRow label="Avatar image">
  //       <FileInput
  //         id="avatar"
  //         accept="image/*"
  //         onChange={(e) => setAvatar(e.target.files[0])}
  //         disabled={isUpdating}
  //       />
  //     </FormRow>

  //     <FormRow>
  //       <Button
  //         type="reset"
  //         variation="secondary"
  //         disabled={isUpdating}
  //         onClick={handleCancel}
  //       >
  //         Cancel
  //       </Button>
  //       <Button disabled={isUpdating}>Update account</Button>
  //     </FormRow>
  //   </Form>
}
