import React, { useContext } from "react";
// import SaveMoneyHero from "../assets/moneySave/How to save.png";
// import SaveMoneyHeroMobile from "../assets/moneySave/Group 94 (5).png";
import MobileMain from '../assets/Group 60 (3)_out (1).png'
import { refContext } from "../store/ContextProvider";
import Image from "../utilities/Image";

const SaveMoney = ({ save, isLoading }) => {
  const refCtx = useContext(refContext);
  return (
    <div ref={refCtx.saveMoney} className="mt-0 md:mt-28 py-10">
      <section className="max-w-7xl mx-auto flex gap-10 md:gap-8 flex-col md:flex-row w-[90%] justify-center">
        <div className="flex items-center">
          <div className="md:max-w-[25rem] mx-auto ">
            {/* Desktop */}
            <Image
              className="mx-auto max-w-full h-auto hidden md:block"
              src="https://stomcnibgnqyffgvnadu.supabase.co/storage/v1/object/public/homepage/moneysave/How%20to%20save%20(1).png"
              alt="savemoney"
            />
            {/* Mobile */}
            <Image
              className="mx-auto block md:hidden"
              src={
                save?.filter((i) => i.imagename == "moneysave_1")[0]?.imageurl
              }
              alt="savemoney"
            />
          </div>
        </div>

        <div className="flex flex-col gap-6">
          <div className="w-full md:w-72">
            <h1 className="text-3xl md:text-3xl font-bold text-center md:text-left">
              How we save you <span className="text-[#22A699]">money</span>?
            </h1>
          </div>
          <div className="bg-white rounded-lg shadow-md max-w-lg p-4 flex-1">
            <h1 className="text-xl text-[#22A699] font-semibold">
              By Uniting the Strength of our Group Members
            </h1>
            <p className="text-md mt-2">
              Our combined voices carry significant power during negotiations.
              As a result, whenever we communicate with lenders, we possess a
              strong advantage in securing better deals for our community.
            </p>
          </div>
          <div className="bg-white rounded-lg shadow-md max-w-lg p-4 flex-1">
            <h1 className="text-xl text-[#22A699] font-semibold">
              Lenders wants our Group business
            </h1>
            <p className="text-md mt-2">
              Banks and other financial institutions are eager to lend money.
              They prefer higher loan volumes, even if it means offering lower
              interest rates, as it benefits them. And when you get better
              rates, it's a win for you too. It’s a rare win-win.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SaveMoney;
