import { useEffect } from "react";
import UpdatePasswordForm from "../components_/UpdatePasswordForm";

export default function Settings() {
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",})
  })
  return (
    <div>
      <h1 className="text-3xl font-semibold md:font-bold text-center md:text-left">Change Your Password</h1>
      <UpdatePasswordForm />
    </div>
  );
}
