import React from "react";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { MdMoneyOff } from "react-icons/md";
import { FcBusinessman } from "react-icons/fc";

const AllFeatures = [
  {
    icon: MdMoneyOff,
    heading: "No Charges",
    text: "SignUp - no charges, no obligations, or credit verification required",
  },
  {
    icon: FcBusinessman,
    heading: "Compete For You",
    headingMobile: "Lenders Compete For You",
    text: "Lenders compete to win your group’s business",
  },
  {
    icon: FaMoneyBillTrendUp,
    heading: "Finest Proposal",
    headingMobile:"Recieve Finest Proposal",
    text: "You will receive the finest proposal that suits your college profile",
  },
];
const Features = () => {
  return (
    <section className=" bg-gradient-to-br from-[#D0F3E4] to-[#6fdeae] mt-14 py-24">
      <div className="flex flex-col lg:flex-row max-w-7xl mx-auto w-[90%] lg:gap-4 gap-6">
  
        <div className="p-4 flex-grow hover:-translate-y-1 flex-1 rounded-lg bg-white duration-200 ease-out hover:shadow-lg">
          <div className="flex gap-4">
            <div>
              <div className="p-4 rounded-lg bg-red-100 shadow-md">
                <MdMoneyOff className="text-2xl text-red-600 " />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-semibold">{AllFeatures[0].heading}</h1>
              <p>
              {AllFeatures[0].text}
              </p>
            </div>
          </div>
        </div>


        <div className="p-4 flex-grow hover:-translate-y-1 flex-1 rounded-lg bg-white duration-200 ease-out hover:shadow-lg">
          <div className="flex gap-4">
            <div>
              <div className="p-4 rounded-lg bg-neutral-600/30 shadow-md">
                <FcBusinessman className="text-2xl  " />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-semibold">
                {AllFeatures[1].heading}
              </h1>
              <p>{AllFeatures[1].text}</p>
            </div>
          </div>
        </div>


        <div className="p-4 flex-grow hover:-translate-y-1 flex-1 rounded-lg bg-white duration-200 ease-out hover:shadow-lg">
          <div className="flex gap-4">
            <div>
              <div className="p-4 rounded-lg bg-green-100 shadow-md">
                <FaMoneyBillTrendUp className="text-2xl text-green-600 " />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-semibold">
                {AllFeatures[2].heading}
              </h1>
              <p>{AllFeatures[2].text}</p>
            </div>
          </div>
        </div>
        
      </div>
    </section>
  );
};

export default Features;
