import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="flex items-center justify-center h-[30rem] bg-gray-100">
    <div className="text-center">
      <h1 className="text-6xl font-bold text-red-500">404</h1>
      <p className="text-2xl font-semibold mt-4">Page not found</p>
      <p className="text-gray-600 mt-2">Sorry, the page you are looking for does not exist.</p>
      <Link to='/'><button className="py-3 px-4 mt-4 text-white rounded-xl bg-[#22a699]">Back to home</button></Link>
    </div>
  </div>
)
;
}
