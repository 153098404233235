import React from 'react'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { Link, useSearchParams } from 'react-router-dom'

const PaymentSuccess = () => {
  const params = useSearchParams();
  const reference = params[0].get('reference');
  return (
    <div className="flex items-center justify-center h-[70vh]">
      <div className="text-center w-[90%] mx-auto">
        <AiOutlineCheckCircle className="w-16 h-16 text-green-500 mx-auto mb-4" />
        <h1 className="text-2xl font-bold text-green-700 mb-2">Payment Successful</h1>
        <p className="text-gray-600">Thank you for your purchase!</p>
        <p className="text-gray-600">Your reference no. is <span className='font-medium '>{reference}</span>.</p>
        <button
          className="mt-6 py-2 px-4 bg-green-500 text-white font-semibold rounded-full hover:bg-green-600 focus:outline-none focus:shadow-outline-green active:bg-green-700"
        >
          <Link to='/dashboard/main'>Dashboard</Link>
        </button>
      </div>
    </div>
  )
}

export default PaymentSuccess
