import { PuffLoader } from "react-spinners";
import "../index.css"; // You can style the loader in a separate CSS file

function LoaderSpinner({children, loading}) {
  return (
    <>
      {loading&&<div >
        <div className="loader-overlay ">
          <PuffLoader size={80} color="#22A699" />
        </div>
      </div>}
      {children}
    </>
  );
}

export default LoaderSpinner;
