import { supabase } from "./supabase";
export default async function SignupApi({
  firstname,
  lastname,
  phonenumber,
  referralcode,
  email,
  password,
}) {
  let { data, error } = await supabase.auth.signUp({
    email: email,
    password: password,
    options: {
      data: {
        firstname: firstname,
        lastname: lastname,
        phonenumber: phonenumber,
        email: email,
        referralcode: referralcode,
      },
    },
  });

  if (error) throw new Error(error.message);
  return data;
}
