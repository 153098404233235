// import { LogoutApi } from "../../secondary/api/LogoutApi";
import { LogoutApi } from "../api/LogoutApi";

import useLogout from "../hook/useLogout";
import "../index.css";

export default function Logout({children, style}) {
  const { logout, isLoading, error } = useLogout();
  function handleLogout() {
    logout();
  }
  return (
    <button className={style} disabled={isLoading} onClick={(e) => handleLogout()}>
      {!isLoading ? children : "Logging out.."}
    </button>
  );
}
