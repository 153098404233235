import React from "react";
import { NavLink } from "react-router-dom";
// import Hero from "../assets/whytrustus/Group 93.png";
// import Team from "../assets/whytrustus/6. about-our-team-3 (1).png";
// import MobileHero from "../assets/whytrustus/Group 60.png";
import DesktopImg from '../assets/Group 93 (2).png'
import MobileImg from '../assets/Group 60 (4)_out.png'
import Image from "../utilities/Image";

const WhyTrustUs = ({ refCtx, trust, isLoading }) => {
  return (
    <div ref={refCtx.aboutusRef} className="mt-4 lg:mt-16">
      <section className="flex">
        <div className="flex flex-col md:flex-row lg:gap-10 w-[100%] justify-center items-center max-w-7xl mx-auto">
          <div className="max-w-[25rem] md:max-w-[30rem]">
            <Image
              src={
                trust?.filter((i) => i.imagename == "trustus_1")[0]?.imageurl
              }
              alt="trust"
            />
          </div>
          <div className="max-w-lg text-center">
            <h1 className="md:text-5xl text-3xl font-semibold">
              Why you should <span className="text-[#22A699]">trust</span> us?
            </h1>
          </div>
        </div>
      </section>
      <section className="mt-6 md:-mt-10">
        <div className="">
          <div className="max-w-[22rem] md:max-w-6xl mx-auto">
            <Image
              className="hidden md:block"
              src={
                DesktopImg
                // trust?.filter((i) => i.imagename == "trustus_3")[0]?.imageurl
              }
              alt="trust"
            />
            <Image
              className="block max-w-full h-auto md:hidden mx-auto"
              src={
                MobileImg
                // trust?.filter((i) => i.imagename == "trustus_2")[0]?.imageurl
              }
              alt="trust"
            />
          </div>
        </div>
      </section>
      <section className="mt-8 md:mt-16">
        <NavLink to="/dashboard/main">
            <button className="mx-auto block py-4 w-[16rem] text-xl px-12 mt-5 rounded-full text-[#DBFFEF] font-bold bg-[#22A699]">
              Join Us-it's free
            </button>
          </NavLink>
      </section>
    </div>
  );
};

export default WhyTrustUs;
