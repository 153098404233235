import React, { useRef } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Home from '../pages/Home'
import { Link, Outlet, useLocation } from 'react-router-dom'

const Layout = () => {
    const location = useLocation();
    
    return (
        <div>
            <Navbar />
                <div className=''>
                    <Outlet />
                </div>
            {(location.pathname==='/login'||location.pathname==='/signup')?null:<Footer />}
            {/* Floating what's app icon */}
            <Link to="https://wa.me/message/QFLYZM5AOC6ZI1" target='_blank' className='fixed w-[60px] h-[60px] right-[25px] md:right-[40px] bottom-[40px] bg-[#25d366] text-[#fff] rounded-[50px] text-[30px] text-center z-[100] ' >
                <i class="fa fa-whatsapp mt-[16px] "></i>
            </Link>
        </div>
    )
}

export default Layout
