import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { ForgotPasswordReset } from "../api/ForgotPasswordReset";

export default function useForgotPasswordReset() {
  //   const queryClient = useQueryClient();

  const { mutate: forgotpassword, isLoading: isUpdating } = useMutation({
    mutationFn: ForgotPasswordReset,
    onSuccess: (user) => {
      toast.success("email successfully sent");
      // console.log("forgot password mail ", user.user);
      //   queryClient.setQueryData(["userdata"], user.user);
    },
    onError: (err) => toast.error(err.message),
  });

  return { forgotpassword, isUpdating };
}
