import { useForm } from "react-hook-form";
import { useUpdatePassword } from "../hook/useUpdatePassword";

function UpdatePasswordForm() {
  const { register, handleSubmit, formState, getValues, reset } = useForm();
  const { errors } = formState;

  const { updatepassword, isUpdating } = useUpdatePassword();

  function onSubmit({ password }) {
    updatepassword(password, { onSuccess: reset });
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-4 ">
      <div>
        <label className="block text-xl font-medium">New password</label>
        <span className="text-red-500 text-sm">{errors?.password?.message}</span>
        <input
          className="block py-2 px-3 w-full md:w-96 rounded-lg outline-none border border-neutral-300 mt-2"      
          type="password"
          id="password"
          autoComplete="current-password"
          placeholder=""
          disabled={isUpdating}
          {...register("password", {
            required: "This field is required",
            minLength: {
              value: 8,
              message: "Password needs a minimum of 6 characters",
            },
          })}
        />
      </div>
      <div className="mt-4">
        <label className="block text-xl font-medium">Confirm password</label>
        <span className="text-red-500 text-sm">{errors?.passwordConfirm?.message}</span>
        <input
          className="block py-2 px-3 w-full md:w-96  rounded-lg outline-none border border-neutral-300 mt-2"
          type="password"
          autoComplete="new-password"
          id="passwordConfirm"
          disabled={isUpdating}
          {...register("passwordConfirm", {
            required: "This field is required",
            validate: (value) =>
              getValues().password === value || "Passwords need to match",
          })}
        />
      </div>

      <button className="mt-4 py-2 px-4 rounded-lg bg-[#22A699] text-white" disabled={isUpdating}>Update password</button>
    </form>
  );
}

export default UpdatePasswordForm;
