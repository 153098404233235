import AnirbanImg from "../../assets/iims/anirban.jfif";
import AnkitaImg from "../../assets/iims/ankita.jfif";
import BanImg from "../../assets/iims/ban.jfif";
import ManojImg from "../../assets/iims/manoj.jpg";
import AshaImg from "../../assets/iims/asha.jpg";
import SunnyImg from "../../assets/iims/sunny.jpg";
import GarimaImg from "../../assets/iims/garima.jpg";


export const Testimonials = [
    {
        name: "Ankita",
        img: AnkitaImg,
        college:"IIM Sambalpur",
        linkedIn: "https://www.linkedin.com/in/deb-ankita?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        testimonial: "I couldn't help but be impressed by the idea. The thought of having personalized guidance for CAT prep and a hassle-free way to find the best MBA loan rates is genuinely brilliant. I wish I had this kind of support when I was starting out. Kudos to the team for such a thoughtful approach"
    },
    {
        name: "Ban",
        img: BanImg,
        college:"IIM Shillong",
        linkedIn: "https://www.linkedin.com/in/baniengskhem-d-nongrum-5192a71b0/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        testimonial: "Back in my day, we didn't have this secret weapon, but imagining it now, it's a total game-changer. The potential to save bucks and sharpen your interview skills? That's a recipe for success right there. Future IIM grads, you've got something exciting coming your way!"
    },
    {
        name: "Anirban",
        img: AnirbanImg,
        college:"IIM Sirmaur",
        linkedIn: "https://www.linkedin.com/in/anirban-das-6903b216a/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        testimonial: "As an IIM alum, the InterestSave concept is a game-changer. Potential savings on student loan with lower interest rates, paired with transparent financial insights and free mock interviews, is a powerhouse combo. Wish it was around during my time—it's the real deal for future IIM grads!"
    },
    {
        name: "Sunny B",
        img: SunnyImg,
        college:"IIM Jammu",
        linkedIn: "https://www.linkedin.com/in/sunny-b-334924176/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        testimonial: "At some of the interviews, I was asked questions regarding policy making. I was completely taken aback by such questions! InterestSave's Mock PI with people pursuing MBA at various top B-Schools will help aspirants to gain real live insights and the pressure of a top B-School interview!"
    },
    {
        name: "Manoj",
        img: ManojImg,
        college:"IIM Bangalore",
        linkedIn: "https://www.linkedin.com/in/monoj-k-chakraborty-9a6311a7/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        testimonial: "During my MBA journey, I had so many questions and queries. InterestSave's Mock PI with feedback & interaction session that helps aspirants get their queries answered by their potential seniors from their Dream B-Schools will give a lot of value to the aspirants!"
    },
    {
        name: "Garima",
        img: GarimaImg,
        college:"IIM Raipur",
        linkedIn: "https://www.linkedin.com/in/garima-23/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        testimonial: "MBA life is full of rigor and it starts with the PIs & GDs. In some of my PIs I grille a lot. Having InterestSave as your Mock PI buddy wherein candidates will have mocks with students who have taken the actual interview just a year back will be very helpful and insightful for the candidates."
    },
    {
        name: "Asha",
        img: AshaImg,
        college:"IIM Ranchi",
        linkedIn: "https://www.linkedin.com/in/asha-christina-h-5a381a279/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        testimonial: "I am currently pursuing MBA HR from IIM Ranchi. Last year, I wanted to connect with people who were currently pursuing the same course. With InterestSave's initiative it will be helpful for candidates to connect and have an interactive session with a senior, get more insights to make a more informed decision!"
    },

]