import React, { useState } from "react";
import { IoCheckmarkOutline } from "react-icons/io5";
import Img from "../assets/33. megaphone-voice-being-heard.png";
import { Link } from "react-router-dom";
// import { Process } from "./Buy";
import Image from "../utilities/Image";

const MockInterview = () => {
  // const [expanded, setExpanded] = useState(false);
  return (
    <div className="py-24 bg-neutral-100 ">
      <div className="relative overflow-hidden w-[90%] mx-auto py-12 pt-24 lg:px-24 bg-white rounded-2xl shadow-lg shadow-neutral-200">
        {/* Corner Ribbon Label */}
        <div className="absolute -rotate-[45deg] block top-12 px-6 lg:-left-20 -left-24 bg-gradient-to-r from-[#22a699] to-[#81d8d0]">
          <p className="py-2 lg:py-[10px] px-16 inline-block text-white font-bold lg:text-xl text-lg">
            Exclusive Launch
          </p>
        </div>

        <div className="flex flex-col lg:flex-row mx-auto max-w-4xl w-[90%]  ">
          <div className="flex-1 order-2 lg:order-1">
            <h1 className="text-5xl font-bold">
              <span className="text-[#22A699]">Free</span> Mock Interview
            </h1>
            <p className="mt-1 font-medium">(For first 1000 students only)</p>
            {/* <h1 className="mt-3 text-2xl font-bold">
              Starting from Jan 2024 <span className="text-[#22A699]"></span>
            </h1> */}
            <h1 className="mt-3 text-3xl font-bold">
              100% <span className="text-[#22A699]">Refund</span>
            </h1>
            <ul className="flex flex-col gap-1 mt-6">
              <li className="flex text-lg items-center gap-2">
                <IoCheckmarkOutline className="text-2xl font-bold text-green-500" />{" "}
                Online mock interview for free{" "}
              </li>
              <li className="flex text-lg items-center gap-2">
                <IoCheckmarkOutline className="text-2xl font-bold text-green-500" />{" "}
                Get interviewed by IIM Alumni{" "}
              </li>
              <li className="flex text-lg items-center gap-2">
                <IoCheckmarkOutline className="text-2xl font-bold text-green-500" />{" "}
                1 on 1 mentorship from IIM alumni{" "}
              </li>
              <li className="flex text-lg items-center gap-2">
                <IoCheckmarkOutline className="text-2xl font-bold text-green-500" />{" "}
                Value learning{" "}
              </li>
            </ul>
            <div className="w-full sm:w-[50%] lg:w-full xl:w-[80%] mt-12">
              <Link to="/signup">
              <button
                className="w-full text-xl bg-[#22A699] py-3 px-6 rounded-full text-[#DBFFEF] font-medium"
              >
                Sign up to know more
              </button>
              </Link>
            </div>
          </div>
          <div className="flex-1 order-1 lg:order-2">
            <div className="md:w-80 w-72 lg:w-[25rem]">
              <Image className="max-w-full h-auto" src={Img} />
            </div>
          </div>
        </div>
        {/* {expanded&&
        <div className="mt-16 w-[90%] mx-auto">
          <Process explainContent={false} />
        </div>
          } */}
      </div>
    </div>
  );
};

export default MockInterview;
