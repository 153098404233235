import { supabase } from "./supabase";

export default async function getHome() {
  //logo, hero, lowest mba,
  let { data: home, error } = await supabase.from("home").select("*");
  if (error) {
    throw new Error("home data couldnot be loaded");
  }
  return home;
}
