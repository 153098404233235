import { useQuery } from "@tanstack/react-query";
import GetCurrentUser from "../api/getCurrentUser";

//get user data and store in react query cache in session exists in local storage
export default function useUser() {
  const { data, isLoading, isFetched } = useQuery({
    queryKey: ["userdata"],
    queryFn: GetCurrentUser,
  });

  return {
    data,
    isLoading,
    isFetched,
    isAuthenticated: data?.role === "authenticated",
  };
}
