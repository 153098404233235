import React, { useContext, useRef, useState } from "react";
import { RiMenu3Line } from "react-icons/ri";
// import Logo from "../assets/Logos/InstSaveLogo (1).png";
import Drawer from "../ui/Drawer";
import Overlay from "../ui/Overlay";
import { Link, useLocation } from "react-router-dom";
import { refContext } from "../store/ContextProvider";
import { useNavigate } from "react-router-dom";

//add new
import useUser from "../hook/useUser";
import { useQuery } from "@tanstack/react-query";
import getHome from "../api/getHomepage";
import ProfileCard from "../ui/ProfileCard";
import Image from "../utilities/Image";
import Logo from '../assets/Logo1 (1).svg'

const Navbar = () => {
  //add new
  const {
    data,
    isLoading: load,
    error,
  } = useQuery({
    queryKey: ["home"],
    queryFn: getHome,
  });

  const { isLoading: loading, isAuthenticated } = useUser();

  //old
  const [expanded, setExpanded] = useState(false);
  const handleProfileClick = () => {
      setExpanded((p)=> !p)
  }
  const [menuOpen, setMenuopen] = useState(false);
  const refCtx = useContext(refContext);
  const navigate = useNavigate();
  const location = useLocation();
  const handleMenu = () => {
    setMenuopen((p) => !p);
  };
  
  return (
    <nav className="py-4 fixed top-0 w-full z-50 bg-[#22A699] shadow-lg shadow-neutral-500/20 lg:shadow-neutral-500/30">
      <div className="flex justify-between items-center max-w-7xl w-[90%] mx-auto">
        {/* Logo */}
        <Link to="/">
          <div onClick={ ()=> window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      })} className="w-28 lg:w-32">
            <Image src={Logo} className='w-12 md:w-16' />
          </div>
        </Link>
        {/* Navlinks */}
        <div className="flex gap-4 lg:gap-10 items-center">
          {!location.pathname.split("/").includes('dashboard') &&<ul className="hidden font-semibold text-lg gap-6 lg:flex items-center text-white">
            <a
              onClick={() => {
                refCtx.scrollToView(refCtx.benefitRef, navigate);
              }}
              className="cursor-pointer"
              id="benefits"
            >
              <li>Benefits</li>
            </a>
            {/* <a
              onClick={() => refCtx.scrollToView(refCtx.podcastRef, navigate)}
              className="cursor-pointer"
              id="podcasts"
            >
              <li>Podcasts</li>
            </a> */}
            <a
              onClick={() => refCtx.scrollToView(refCtx.aboutusRef, navigate)}
              className="cursor-pointer"
              id="aboutus"
            >
              <li>About Us</li>
            </a>
            <Link to="/buy">
              <li>GDPI</li>
            </Link>
            <Link target="_blank" to="https://www.youtube.com/@InterestSave/videos">
              <li>Podcasts</li>
            </Link>
            <a
              onClick={() => refCtx.scrollToView(refCtx.faqsRef, navigate)}
              className="cursor-pointer"
              id="faqs"
            >
              {" "}
              <li>FAQs</li>
            </a>
          </ul>}
          <div className="">
            {isAuthenticated && !loading ? (
              // <Link to="/dashboard/main">
              //   <button className="py-2 shadow-black/30 duration-200 hover:shadow-lg px-4 rounded-full bg-[#22A699] text-white font-medium text-lg">
              //     Dashboard
              //   </button>
              // </Link>
              <ProfileCard handleProfileClick={handleProfileClick} expanded={expanded} />
              // <div></div>
            ) : (
              <div className="flex gap-2">
                <Link className="hidden lg:block" to="/signup">
                  <div className="w-[7rem]">
                    <button className="py-2 w-full border-[1.5px] border-white shadow-black/30 duration-200 hover:shadow-lg px-4 rounded-full bg-[#22A699] text-white font-medium text-lg">
                      Sign up
                    </button>
                  </div>
                </Link>
                <Link className="hidden lg:block" to="/login">
                  <div className="w-[7rem]">
                    <button className="py-2 w-full border-[1.5px] border-white shadow-black/30 duration-200 hover:shadow-lg px-4 rounded-full bg-[#22A699] text-white font-medium text-lg">
                      Log in
                    </button>
                  </div>
                </Link>
              </div>
            )}
          </div>
          <div onClick={handleMenu} className="lg:hidden block">
            {!menuOpen && <RiMenu3Line className="text-3xl sm:text-4xl text-white" />}
          </div>
        </div>
      </div>
      <Drawer
        generateProfile = {<ProfileCard />}
        menuOpen={menuOpen}
        handleMenu={handleMenu}
        isAuthenticated={isAuthenticated}
        isLoading={loading}
      />
      <Overlay style='h-screen top-0 z-20 duration-200 transition-all fixed bg-neutral-700 ' onClickFunc={handleMenu} open={menuOpen} />
      <Overlay style='h-screen fixed top-0 duration-200 transition-all bg-neutral-800 ' onClickFunc={handleProfileClick} open={expanded} />
    </nav>
  );
};

export default Navbar;
