import React from 'react'
import { AiFillPieChart } from "react-icons/ai";
import { FaUserEdit } from "react-icons/fa";
import { GoGear } from "react-icons/go";
import { PiSignOut } from "react-icons/pi";
import { NavLink, Outlet } from 'react-router-dom';
import Logout from '../components_/Logout';


const Dashboard = () => {
  return (
    <div className='flex '>
      <aside className='py-4 px-6 bg-[#F1FFF9] hidden lg:block'>
        {/* Logo */}
        <div className=' flex flex-col items-center'>
            
            {/* Dashboard */}
            <div className='flex flex-col gap-2 w-full h-[34rem]'>

            <NavLink className={({isActive})=>isActive?'bg-[#22A699] text-white rounded-xl':'text-neutral-600 duration-200 hover:bg-neutral-200 rounded-xl'} to='/dashboard/main'>
              <div className='w-full rounded-xl '>
                  <button className='flex w-full py-3 px-8 flex-row items-center gap-3'>
                      <AiFillPieChart className='text-2xl' />
                      <p className='text-lg'>Dashboard</p>
                  </button>
              </div>
            </NavLink>

            {/* Dashboard Links */}

                <NavLink className={({isActive})=>isActive?'bg-[#22A699] text-white rounded-xl':'text-neutral-600 duration-200 hover:bg-neutral-200 rounded-xl'} to='/dashboard/profile'>
                  <div className='w-full rounded-xl '>
                      <button className='flex w-full py-3 px-8 flex-row items-center gap-3 '>
                          <FaUserEdit className='text-2xl' />
                          <p className='text-lg'>Edit Profile</p>
                      </button>
                  </div>
                </NavLink>
                {/* <div className='w-full duration-200 hover:bg-neutral-200 rounded-xl'>
                    <button className='flex w-full py-3 px-8 flex-row items-center gap-3  text-neutral-600'>
                        <BsGraphUp className='text-2xl' />
                        <p className='text-lg'>Calculator</p>
                    </button>
                </div> */}
                {/* <div className='w-full duration-200 hover:bg-neutral-200 rounded-xl'>
                    <button className='flex w-full py-3 px-8 flex-row items-center gap-3  text-neutral-600'>
                        <MdOutlineMessage className='text-2xl' />
                        <p className='text-lg'>FAQ</p>
                    </button>
                </div> */}
                {/* <div className='w-full duration-200 hover:bg-neutral-200 rounded-xl'>
                    <button className='flex w-full py-3 px-8 flex-row items-center gap-3  text-neutral-600'>
                        <MdOutlineGroup className='text-2xl' />
                        <p className='text-lg'>Community</p>
                    </button>
                </div> */}
                <NavLink className={({isActive})=>isActive?'bg-[#22A699] text-white rounded-xl':'text-neutral-600 duration-200 hover:bg-neutral-200 rounded-xl'} to='/dashboard/settings' >
                  <div className='w-full rounded-xl '>
                      <button className='flex w-full py-3 px-8 flex-row items-center gap-3 '>
                          <GoGear className='text-2xl' />
                          <p className='text-lg'>Settings</p>
                      </button>
                  </div>
                </NavLink>

                  <div className='w-full duration-200 hover:bg-neutral-200 rounded-xl'>
                    <Logout style='w-full'>
                      <div className='flex w-full py-3 px-8 flex-row items-center gap-3  text-neutral-600'>
                          <PiSignOut className='text-2xl' />
                          Log Out
                      </div>
                    </Logout>
                  </div>
                
            </div>
        </div>
        
      </aside>
      <aside className='flex-1 lg:mt-0'>
        {/* Top Right */}
        {/* <div className='py-4 px-6 bg-[#DBFFEF] flex items-center justify-between'>
            <div>
                <h1 className='font-semibold text-xl'>Dashboard</h1>
            </div>
            <div className='flex gap-4'>
                <div className='w-10 rounded-lg' >
                    <img className='rounded-lg' src={DemoProfile} alt="profile"></img>
                </div>
                <div className='flex justify-between gap-8'>
                    <p>Musfiq</p>
                    <MdOutlineArrowDropDown />
                </div>
            </div>
        </div> */}
        {/* Main Content Right */}
        <div className='py-6 px-4 h-full'>   
            <Outlet />
        </div>
      </aside>
    </div>
  )
}

export default Dashboard
