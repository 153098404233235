import React from "react";
import Slider from "react-slick";
import { Testimonials } from "./data";
import { Settings } from "./slider.config";
import Testimonial from "./Testimonial";

const HearFromIIMs = ({ hear, isLoading }) => {
  return (
    <div className="mt-20">
      <div className="text-center">
        <h1 className="text-3xl md:text-4xl max-w-lg w-[90%] mx-auto font-bold">
          See what your friends at top{" "}
          <span className="text-[#22A699]">B-school</span> says
        </h1>
      </div>
      <div className=" bg-[#22A699] mt-6 text-white ">
        <div className="max-w-8xl mx-auto lg:w-[90%] w-[80%] py-8 md:py-12 ">
          <Slider {...Settings}>
            {Testimonials.map(testimonial=><Testimonial name={testimonial.name} img={testimonial.img} testimonial={testimonial.testimonial} linkedIn={testimonial.linkedIn} college={testimonial.college} />)}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default HearFromIIMs;
